import {Context, createContext, useContext, useEffect, useState} from "react";
import AuthUser, {User} from "../Backend/Services/Auth/AuthUser";
import useHandleAxiosRequest from "../Hooks/useHandleAxiosRequest";
import getUserInfo from "../Backend/Api/Auth/get-user-info";
import AuthToken from "../Backend/Services/Auth/AuthToken";
import login from "../Backend/Api/Auth/login";
import {loginError, loginSuccess, logoutSuccess} from "../slices/auth/login/reducer";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

export type AuthContextType = {
    user?: User,
    setUser?: (user: User|undefined) => void;
    setToken?: (token: string|undefined) => void;
    token?: string;
};

export const AuthContext: Context<AuthContextType> = createContext({});

export const AuthProvider = ({children}: any) => {
        const [user, setUser] = useState<User | undefined>(undefined);
        const [token, setToken] = useState<string|undefined>(undefined);
        const [hasError, setHasError] = useState<boolean>(false);
        const [, , handleRequest] = useHandleAxiosRequest();

        function saveUser(user: User|undefined) {
            setUser(user);
            if(!user)  {
                AuthUser.remove();
                return;
            }
            AuthUser.set(user);
        }

        function saveToken(token: string|undefined) {
            setToken(token);
            if(!token) {
                AuthToken.removeToken();
                return;
            }
            AuthToken.setToken(token);
        }

        const fetchUser = async () => {
            if (!AuthToken.getToken()) {
                return;
            }
            const userData = AuthUser.get();
            if (userData) {
                saveUser(userData);
                return;
            }

            handleRequest(getUserInfo)
                .then((response: User) => {
                    saveUser(response);
                })
                .catch(() => {
                    setHasError(true)
                });
        };

        useEffect(() => {
            if (!AuthToken.getToken()) {
                return;
            }

            if (user) {
                return;
            }
            if (hasError) {
                return;
            }

            fetchUser();
        }, []);

        return (
            <AuthContext.Provider value={{
                user,
                token,
                setUser: saveUser,
                setToken: saveToken
            }}>
                {children}
            </AuthContext.Provider>
        );
    }
;

export type UserCredentials = {
    email: string;
    password: string;
};

export type UseAuthenticatedUserType = {
    loginUser: (user: UserCredentials) => Promise<void>;
    logout: () => void;
    setUser: (user: User) => void;
    setToken: (token: string) => void;
    user?: User;
    token?: string;
};

export default function useAuthenticatedUser(): UseAuthenticatedUserType {
    const {setUser, setToken, user, token} = useContext(AuthContext);
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    function logout() {
        setUser && setUser(undefined);
        setToken && setToken(undefined);
        AuthToken.removeToken();
        AuthUser.remove();
        dispatch(logoutSuccess(true));
        setTimeout(() => {
            navigate("/login");
        }, 2000);
    }

    async function loginUser (user: UserCredentials)  {
        try {
            const authToken = await login(
                user.email,
                user.password,
            );
            AuthToken.setToken(authToken)
            setToken && setToken(authToken);
            const userLogged = await getUserInfo(authToken);
            AuthUser.set(userLogged);
            setUser && setUser(userLogged);
            dispatch(loginSuccess(userLogged));
        } catch (error) {
            if (error instanceof Error) {
                dispatch(loginError(error));
            }
        }
    }

    return {
        user,
        token,
        loginUser,
        logout,
        setUser: (user: User) => setUser && setUser(user),
        setToken: (token: string) => setToken && setToken(token),
    }
}
