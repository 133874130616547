import React from "react";

export default function MovementStatus({is_completed, type = null}: { is_completed: any, type?: any }) {
    if (!is_completed) {
        return (
            <span
                className="px-2.5 py-0.5 inline-block text-[11px] font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:border-transparent"
            >
                Em Aberto
            </span>
        );

    }
    switch (type) {
        case "cash_in":
            return (<span
                className="px-2.5 py-0.5 inline-block text-[11px] font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">Recebido</span>);
        case "cash_out":
            return (<span
                className="px-2.5 py-0.5 inline-block text-[11px] font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">Pago</span>);
        default:
            return (<span
                className="px-2.5 py-0.5 inline-block text-[11px] font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">Pago</span>);
    }
}
