import {Context, createContext, useContext, useState} from "react";
import {AxiosError} from "axios";

export type FieldError = {
    name: string;
    errors: string[];
}
export type ApiErrorsContextType = {
    errors: FieldError[];
    setErrors: (errors: FieldError[]) => void;
};

export const LaravelErrorsContext: Context<any> = createContext({
    errors: [],
});

export const ApiErrorsProvider = ({children}: any) => {
    const [errors, setErrors] = useState<FieldError[]>([]);

    return (
        <LaravelErrorsContext.Provider value={{
            errors,
            setErrors,
        }}>
            {children}
        </LaravelErrorsContext.Provider>
    );
}

export type UseApiErrorsType = ApiErrorsContextType & {
    hasError: () => boolean;
    setErrors: (error: any) => void;
    errors: FieldError[];
}
export function useApiErrors (): UseApiErrorsType {
    const context = useContext(LaravelErrorsContext);
    if (!context) {
        throw new Error("useLaravelErrors must be used within a LaravelErrorsProvider");
    }
    function setErrors(error: any) {
        if((error instanceof AxiosError) && error.response?.data?.errors) {
            context.setErrors(Object.keys(error.response.data.errors).map((fieldName: string) => {
                return {
                    name: fieldName,
                    errors: error.response?.data?.errors?.[fieldName]
                }
            }));
        }
        context.setErrors([]);
    }

    return {
        ...context,
        hasError: () => context.errors.length > 0,
        setErrors: setErrors
    };
}