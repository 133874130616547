import getApi from "../axios/api";
import { AxiosResponse } from "axios";
import InputMasker from "../../Services/Helpers/InputMasker";

export type AmountCamelCase = {
    amountString: string;
    amountCents: number;
}

export type SaleList = {
    id: number;
    seller: string;
    productType: string;
    productBrand: string;
    productModel: string;
    productPlate?: string;
    productValue: AmountCamelCase;
    value: AmountCamelCase;
    commission: AmountCamelCase;
    createdAt: string;
}

export default async function listSales(startDate?: Date|null, endDate?: Date|null) {
    let params: any = {};
    if (startDate) {
        params.start_date = InputMasker.formatDateToApi(startDate);
    }
    if (endDate) {
        params.end_date = InputMasker.formatDateToApi(endDate);
    }
    let queryString = new URLSearchParams(params).toString();

    const response: AxiosResponse<SaleList[]> = await getApi().get("/api/sales" + (queryString ? '?' + queryString : ''));
    return response.data;
}
