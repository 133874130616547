import getApi from "../axios/api";

export type CreateUserPayload = {
    name: string;
    email: string;
    role: string;
}

export default async function createUser(payload: CreateUserPayload): Promise<void> {
    await getApi().post("/api/user", payload);
}
