import React, {useEffect, useMemo} from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import {Link} from 'react-router-dom';
import TableContainer from 'Common/TableContainer';
import {FileDown, Search} from 'lucide-react';
import {ToastContainer} from 'react-toastify';
import useHandleAxiosRequest from "../../Hooks/useHandleAxiosRequest";
import getProducts, {Product, ProductFilters} from "../../Backend/Api/Product/get-products";
import CsvFormatter from "../../Backend/Services/ExportCsv/CsvFormatter";
import CsvHelper from "../../Backend/Services/ExportCsv/CsvHelper";
import useObjectState from "../../Hooks/useObjectState";

const ProductSoldList = () => {
    const [productList, setProductList] = useHandleAxiosRequest(async () => getProducts({status: 'Vendida'}), [])
    const [filteredProducts, setFilteredProducts] = useHandleAxiosRequest(async () => getProducts({status: 'Vendida'}), [])
    const [, , handleRequest] = useHandleAxiosRequest();
    const [productFilters, setProductFilter] = useObjectState<ProductFilters>({status: 'Vendida'})

    function handleDownloadCsv() {
        const productsCsvFormat = CsvFormatter.formatProductToCsvObject(productList as Product[]);
        CsvHelper.handleDownloadCsv(productsCsvFormat, 'relatorio_de_veiculos.csv');
    }

    function reloadProducts() {
        handleRequest(() => getProducts(productFilters))
            .then((response: any) => {
                setFilteredProducts(response)
                setProductList(response);
            });
    }

    const ColorStatus = ({item}: any) => {
        switch (item) {
            case "Em Preparação":
                return (<span
                    className="px-2.5 py-0.5 text-xs font-medium rounded border bg-yellow-400 border-transparent  dark:bg-green-500/20 dark:border-transparent inline-flex items-center status"
                >{item}</span>);
            case "Oficina":
                return (<span
                    className="px-2.5 py-0.5 text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-550 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status"
                >{item}</span>);
            case "Aguardando Anúncios":
                return (<span
                    className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status"
                >{item}</span>);
            case "Online":
                return (<span
                    className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-blue-400 border-transparent text-white dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status"
                >{item}</span>);
            case "Devolvida":
                return (<span
                    className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-400 border-transparent text-white dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status"
                >{item}</span>);
            case "Vendida":
                return (<span
                    className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-green-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status"
                >{item}</span>);
            default:
                return (<span
                    className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status"
                >{item}</span>);
        }
    };

    useEffect(() => {
        reloadProducts();
    }, [productFilters])

    const columns = useMemo(() => [
            {
                header: "ID",
                accessorKey: "id",
                enableColumnFilter: false,

                cell: (cell: any) => (
                    <Link
                        to={"/products/details/" + cell.getValue()}
                        className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"
                    >
                        {cell.getValue()}
                    </Link>
                ),
            },
            {
                header: "Status",
                accessorKey: "status.name",
                enableColumnFilter: false,
                cell: (cell: any) => <ColorStatus item={cell.getValue()}/>
            },
            {
                header: "Placa",
                accessorKey: "placa",
                enableColumnFilter: false,
            },
            {
                header: "Marca",
                accessorKey: "marca",
                enableColumnFilter: false,
            },
            {
                header: "Modelo",
                accessorKey: "modelo",
                enableColumnFilter: false,
            },
            {
                header: "Ano",
                accessorKey: "ano",
                enableColumnFilter: false,
            },
            {
                header: "Fabricação",
                accessorKey: "fabricacao",
                enableColumnFilter: false,
            },
            {
                header: "Tipo",
                accessorKey: "product_type.description",
                enableColumnFilter: false,
            },
            {
                header: "Valor",
                accessorKey: "valor.amount_string",
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <span className="text-base">R$ {cell.getValue()}</span>
                ),
            },
            {
                header: "Valor Tabela Fipe",
                accessorKey: "valor_fipe.amount_string",
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <span className="text-base">R$ {cell.getValue()}</span>
                ),
            },
            {
                header: "Diferença",
                accessorKey: "diferenca",
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <span className="text-base"
                          style={{color: cell.getValue().amount_cents > 0 ? 'red' : 'blue'}}>R$ {cell.getValue().amount_string}</span>
                ),
            },
            {
                header: "Data de criação",
                accessorKey: "created_at",
                enableColumnFilter: false,
            },
        ], []
    );

    return (
        <React.Fragment>
            <BreadCrumb title='Listagem' pageTitle='Produtos'/>
            <ToastContainer closeButton={false} limit={1}/>
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
                <div className="xl:col-span-12">
                    <div className="card" id="usersTable">
                        <div className="card-body">
                            <div className="flex items-center">
                                <h6 className="text-15 grow">Produtos Vendidos</h6>
                                <div className="flex items-end gap-2 w-[450px]">
                                    <button
                                        type="button"
                                        onClick={() => handleDownloadCsv()}
                                        className="px-2 py-1.5 text-base text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 rounded-md  font-bold h-[40px] sm:w-full sm:mt-5 xl:w-[280px] 2xl:w-[280px] md:w-[280px] flex items-center justify-around"
                                    >
                                        Exportar Relatorio
                                        <FileDown height={'25px'}/>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
                            <form action="#!">
                                <div className="grid grid-cols-4 gap-5 xl:grid-cols-12">
                                    <div className="relative xl:col-span-2">
                                        <input
                                            type="text"
                                            className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Busque por placa"
                                            autoComplete="off"
                                            onChange={(e) => setProductFilter('placa', e.target.value)}
                                            value={productFilters.placa ?? ''}
                                        />
                                        <Search
                                            className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600"
                                        />
                                    </div>
                                    <div className="relative xl:col-span-2">
                                        <input
                                            type="text"
                                            className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Busque por marca "
                                            autoComplete="off"
                                            onChange={(e) => setProductFilter('marca', e.target.value)}
                                            value={productFilters.marca ?? ''}
                                        />
                                        <Search
                                            className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600"/>
                                    </div>
                                    <div className="relative xl:col-span-2">
                                        <input
                                            type="text"
                                            className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Busque por modelo"
                                            autoComplete="off"
                                            onChange={(e) => setProductFilter('modelo', e.target.value)}
                                            value={productFilters.modelo ?? ''}

                                        />
                                        <Search
                                            className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600"
                                        />
                                    </div>
                                    <div className="relative xl:col-span-2">
                                        <select
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            onChange={(e) => setProductFilter('limit', e.target.value)}
                                            value={productFilters.limit ?? ''}
                                        >
                                            <option value="50" className=''>Limite (default 50)</option>
                                            <option value="10" className=''>10</option>
                                            <option value="20" className=''>20</option>
                                            <option value="50" className=''>50</option>
                                            <option value="100" className=''>100</option>
                                            <option value="0" className=''>Sem limite</option>

                                        </select>
                                    </div>
                                    <div className="relative xl:col-span-2">
                                        <select
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            onChange={(e) => setProductFilter('orderBy', e.target.value)}
                                            value={productFilters.orderBy ?? ''}
                                        >
                                            <option value="" className=''>Ordenar por</option>
                                            <option value="products.id" className=''>Ordenar por Id</option>
                                            <option value="products.marca" className=''>Ordenar por Marca</option>
                                            <option value="products.marca" className=''>Ordenar por Modelo</option>
                                        </select>
                                    </div>
                                    <div className="relative xl:col-span-2">
                                        <select
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            onChange={(e) => setProductFilter('orderType', e.target.value)}
                                            value={productFilters.orderType ?? ''}
                                        >
                                            <option value="" className=''>Ordenar crescente/decrescente</option>
                                            <option value="asc" className=''>Crescente</option>
                                            <option value="desc" className=''>Decrescente</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="card-body">
                            {filteredProducts && filteredProducts.length > 0 ?
                                <TableContainer
                                    isPagination={true}
                                    columns={(columns || [])}
                                    data={(filteredProducts || [])}
                                    customPageSize={20}
                                    divclassName="overflow-x-auto"
                                    tableclassName="w-full whitespace-nowrap"
                                    theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
                                    thclassName="px-3.5  first:pl-5 last:pr-5 font-semibold border-y border-slate-200 dark:border-zink-500"
                                    tdclassName="px-3.5 h-max-[5px] h-[5px] first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
                                    trclassName={'h-[5px]'}
                                    PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                                />
                                :
                                (<div className="noresult">
                                <div className="py-6 text-center">
                                    <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20"/>
                                        <h5 className="mt-2 mb-1">Nenhum produto encontrado!</h5>
                                    </div>
                                </div>)}
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ProductSoldList;
