import axios, {AxiosError} from 'axios';
import env from "../../../env";
import AuthToken from "../../Services/Auth/AuthToken";

export type ApiError = {
  message?: string,
}

export type AxiosWithApiError = AxiosError<ApiError>;

export default function getApi() {
  return axios.create({
    baseURL: env.apiBaseUrl,
    headers: {
      Authorization: 'Bearer ' + AuthToken.getToken()
    }
  });

}
