import {useEffect, useState} from "react";
import ErrorHelper from "../Backend/Services/Helpers/ErrorHelper";
import {useNavigate} from "react-router-dom";
import {useApiErrors} from "../Providers/ApiErrorsProvider";


export type ExecuteRequestOutput = {
    then: (callback: any) => ExecuteRequestOutput;
    catch: (callback: any) => ExecuteRequestOutput;
}

export default function useHandleAxiosRequest(request?: () => Promise<any>, initialValue: any = null) {
    const {setErrors} = useApiErrors();
    const [value, setValue] = useState(initialValue);
    const [hasError, setHasError] = useState(false);
    const navigate = useNavigate();

    function executeRequest(request: () => Promise<any>): ExecuteRequestOutput {
        let successCallback: any = null;
        let errorCallback: any = null;

        request()
            .then((response) => {
                setValue(response);
                successCallback && successCallback(response)
            })
            .catch((error) => {
                setErrors(error);
                setHasError(true);
                ErrorHelper.handleError(error, navigate);
                errorCallback && errorCallback(error)
            });

        return {
            then: function (callback: any): ExecuteRequestOutput {
                successCallback = callback;
                return this;
            },
            catch: function (callback: any): ExecuteRequestOutput {
                errorCallback = callback;
                return this;
            },
        };

    }

    useEffect(() => {
        if (hasError) {
            return;
        }
        if (value !== initialValue) {
            return;
        }
        if (request) {
            executeRequest(request);
        }
    }, [value]);
    return [value, setValue, executeRequest]
}