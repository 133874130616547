import React, {ReactNode} from "react";
import {Navigate} from "react-router-dom";
import AuthToken from "../Backend/Services/Auth/AuthToken";
import AuthUser from "../Backend/Services/Auth/AuthUser";

interface AuthProtectedProps {
    children: ReactNode;
}

const AuthProtected: React.FC<AuthProtectedProps> = ({children}) => {
    if (!AuthUser.get() || !AuthToken.getToken()) {
        return <Navigate to={{pathname: "/login"}}/>;
    }

    return <React.Fragment>{children}</React.Fragment>;
};

export default AuthProtected;
