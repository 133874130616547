import React from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import { useParams } from 'react-router-dom';

// Icons
import { ToastContainer } from 'react-toastify';
import useHandleAxiosRequest from 'Hooks/useHandleAxiosRequest';
import getProductsById from 'Backend/Api/Product/get-products_by_id';
import ImageCarrousel from 'Common/Components/Modal/ImageCarrousel';
import TextAreaWithLabel from 'pages/Components/Custom/TextAreaWithLabel';
import { useFormik } from 'formik';
import InputWithLabel from 'pages/Components/Custom/InputWithLabel';
import SelectInputWithLabel from 'pages/Components/Custom/SelectInputWithLabel';
import AmountInputWithLabel from 'pages/Components/Custom/AmountInputWithLabel';
import InputMasker from "../../Backend/Services/Helpers/InputMasker";
import getProductsStatus from 'Backend/Api/Product/get-products-status';
import { UploadMediaResponse } from 'Backend/Api/Media/upload-media';
import ImageModal from 'Common/Components/Modal/ImageModal';

const ProductDetails = () => {
    const { id }: any = useParams();
    const [productList, setProductList]: any = useHandleAxiosRequest(async () => getProductsById(id), [])
    const [productStatus,] = useHandleAxiosRequest(async () => getProductsStatus(), [])

    function handleDeleteImage(file: UploadMediaResponse) {

    }


    const validation: any = useFormik({
        enableReinitialize: true,

        initialValues: {
            placa: productList?.placa ?? '',
            marca: productList?.marca ?? '',
            modelo: productList?.modelo ?? '',
            ano: productList?.ano ?? '',
            cor: productList?.cor ?? '',
            km: productList?.km ?? '',
            fabricacao: productList?.fabricacao ?? '',
            valor: productList?.valor?.amount_string ?? '',
            valor_fipe: productList?.valor_fipe?.amount_string ?? '',
            commission: productList?.commission ?? '',
            product_type: productList?.product_type?.name ?? '',
            ipva_pago: productList?.ipva_pago,
            ticket_to_auction: productList?.ticket_to_auction,
            manually: productList?.manually,
            spare_key: productList?.spare_key,
            acessorios: productList?.acessorios ?? '',
            document: productList?.document ?? '',
            laudo_cautelar: productList?.laudo_cautelar ?? '',
            cnh: productList?.cnh ?? '',
            historico_manutencao: productList?.historico_manutencao ?? '',
            client_name: productList?.client_name ?? '',
            client_phone: productList?.client_phone ?? '',
            status_id: productList?.status?.id ?? '',
        },

        onSubmit: (values: any, formikHelpers: any) => {

        },
    });
    return (
        <React.Fragment>
            <BreadCrumb title='Detalhes' pageTitle='Produtos' />
            <ToastContainer closeButton={false} limit={1} />
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
                <div className="xl:col-span-12">
                    <div className="card " id="usersTable">
                        <div className="card-body">
                            <div className="flex items-center">
                                <h6 className="text-15 grow">Detalhe do produto</h6>

                            </div>
                        </div>
                        <div
                            className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500 bg-slate-100">
                            <form
                                action="#!"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}>
                                <div className='bg-white rounded-md shadow-lg mb-5'>
                                    <div className='grid gap-2 grid-cols-4 p-2'>
                                        <InputWithLabel
                                            validation={validation}
                                            name="placa"
                                            label='Placa'
                                            id="placaInput"
                                            placeholder="Digite a placa do veiculo"
                                        />
                                        <InputWithLabel
                                            validation={validation}
                                            name="marca"
                                            label='Marca'
                                            id="marcaInput"
                                            placeholder="Digite a marca do veiculo"
                                        />
                                        <InputWithLabel
                                            validation={validation}
                                            name="modelo"
                                            label='Modelo'
                                            id="modeloInput"
                                            placeholder="Digite o modelo do veiculo"
                                        />
                                        <SelectInputWithLabel
                                            validation={validation}
                                            name="status_id"
                                            id="status_id"
                                            label="Status"
                                            options={productStatus.map((status: any) => {
                                                return {
                                                    value: status.id, description: status.name
                                                }
                                            })}
                                        />

                                    </div>
                                    <div className='grid gap-2 grid-cols-3 p-2'>
                                        <AmountInputWithLabel
                                            validation={validation}
                                            name="valor"
                                            label='Valor do veiculo'
                                            id="valueInput"
                                        />
                                        <AmountInputWithLabel
                                            validation={validation}
                                            name="valor_fipe"
                                            label='Valor Tabela Fipe'
                                            id="fipeValueInput"
                                        />

                                        <InputWithLabel
                                            validation={validation}
                                            name="commission"
                                            label='Comissão ( % )'
                                            id="commissionId"
                                            placeholder="Comissão ( % )"
                                            mask={InputMasker.maskPercentage}
                                        />

                                    </div>
                                </div>
                                <div className='bg-white rounded-md shadow-lg mb-5'>
                                    <div className='grid gap-2 grid-cols-2 p-2'>
                                        <InputWithLabel
                                            validation={validation}
                                            name="fabricacao"
                                            label='Fabricação'
                                            id="fabricationInput"
                                            placeholder="Digite o ano de fabricação"
                                            mask={InputMasker.maskYear}
                                        />
                                        <InputWithLabel
                                            validation={validation}
                                            name="ano"
                                            label='Ano ( modelo )'
                                            id="yearInput"
                                            placeholder="2024"
                                            mask={InputMasker.maskYear}
                                        />
                                        <InputWithLabel
                                            validation={validation}
                                            name="km"
                                            label='Km'
                                            id="kmInput"
                                            placeholder="Digite a kilometragem"
                                            mask={InputMasker.maskNumber}
                                        />
                                        <InputWithLabel
                                            validation={validation}
                                            name="cor"
                                            label='Cor'
                                            id="colorInput"
                                            placeholder="Digite a cor do veiculo"
                                            mask={InputMasker.maskPureString}
                                        />
                                    </div>

                                    <div className='grid gap-2 grid-cols-3 p-2'>
                                        <SelectInputWithLabel
                                            validation={validation}
                                            name="product_type"
                                            id="typeInput"
                                            label="Tipo do veiculo"
                                            options={[
                                                { value: 'veiculo_consignado', description: 'Veículo Consignado' },
                                                { value: 'veiculo_proprio', description: 'Veículo Próprio' },
                                            ]}
                                        />
                                        <SelectInputWithLabel
                                            validation={validation}
                                            name="ipva_pago"
                                            id="ipvaInput"
                                            label='IPVA Pago'
                                            options={[
                                                { value: true, description: 'Sim' },
                                                { value: false, description: 'Não' },
                                            ]}
                                        />

                                        <SelectInputWithLabel
                                            validation={validation}
                                            name="ticket_to_auction"
                                            id="ticket_to_auction"
                                            label='Passagem em Leilão'
                                            options={[
                                                { value: true, description: 'Sim' },
                                                { value: false, description: 'Não' },
                                            ]}
                                        />
                                    </div>
                                    <div className='grid gap-2 grid-cols-2 p-2'>

                                        <SelectInputWithLabel
                                            validation={validation}
                                            name="spare_key"
                                            id="spare_key"
                                            label='Chave reserva'
                                            options={[
                                                { value: true, description: 'Sim' },
                                                { value: false, description: 'Não' },
                                            ]}
                                        />

                                        <SelectInputWithLabel
                                            validation={validation}
                                            name="manually"
                                            id="manually"
                                            label='Manual'
                                            options={[
                                                { value: true, description: 'Sim' },
                                                { value: false, description: 'Não' },
                                            ]}
                                        />
                                    </div>
                                </div>
                                {
                                    validation.values?.product_type === 'veiculo_consignado' && (
                                        <div className='bg-white rounded-md shadow-lg mb-5'>
                                            <div className='grid gap-2 grid-cols-2 p-2'>
                                                <InputWithLabel
                                                    validation={validation}
                                                    name="client_name"
                                                    label='Nome do Cliente'
                                                    id="clientNameInput"
                                                    placeholder="Digite o nome do cliente"
                                                />
                                                <InputWithLabel
                                                    validation={validation}
                                                    name="client_phone"
                                                    label='Telefone do Cliente'
                                                    id="clientPhoneInput"
                                                    placeholder="Digite o telefone do cliente"
                                                    mask={InputMasker.maskPhone}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                                <div className='bg-white rounded-md shadow-lg mb-5 max-w-full'>
                                    <ImageCarrousel images={productList.images} onDelete={handleDeleteImage} />
                                </div>

                                <div className='bg-white rounded-md shadow-lg mb-5'>
                                    <div className='grid gap-4 grid-cols-4 p-2'>
                                        {productList?.document
                                            && <div>
                                                <label
                                                    className="inline-block mb-2 text-base font-medium w-full text-center"
                                                >
                                                    CRLV-e
                                                </label>
                                                <ImageModal
                                                    isDocument={true}
                                                    image={productList?.document}
                                                    onDelete={handleDeleteImage}
                                                />
                                            </div>
                                        }

                                        {productList?.laudo_cautelar
                                            && <div>
                                                <label
                                                    className="inline-block mb-2 text-base font-medium w-full text-center"
                                                >
                                                    Laudo Cautelar
                                                </label>
                                                <ImageModal
                                                    isDocument={true}
                                                    image={productList?.laudo_cautelar}
                                                    onDelete={handleDeleteImage}
                                                />
                                            </div>
                                        }
                                        {productList?.cnh
                                            && <div>
                                                <label
                                                    className="inline-block mb-2 text-base font-medium w-full text-center"
                                                >
                                                    CNH
                                                </label>
                                                <ImageModal
                                                    isDocument={true}
                                                    image={productList?.cnh}
                                                    onDelete={handleDeleteImage}
                                                />
                                            </div>
                                        }
                                        {productList?.historico_manutencao
                                            && <div>
                                                <label
                                                    className="inline-block mb-2 text-base font-medium w-full text-center"
                                                >
                                                    Histórico de Manutenção
                                                </label>
                                                <ImageModal
                                                    isDocument={true}
                                                    image={productList?.historico_manutencao}
                                                    onDelete={handleDeleteImage}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className='p-2'>
                                        <TextAreaWithLabel
                                            validation={validation}
                                            name="acessorios"
                                            label='Acessorios'
                                            id="accessoriesInput"
                                            placeholder="Digite os acessorios do veiculo"
                                            defaultRows={5}
                                        />
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>
    );
};

export default ProductDetails;
