import getApi from "../axios/api";
import {AxiosResponse} from "axios";

export type Product = {
    id: number;
    name: string;
}


export default async function getProductColors(): Promise<string[]> {
    const response: AxiosResponse<string[]> = await getApi().get("/api/product/filters/colors");
    return response.data;
}