import React from "react";
import BreadCrumb from "Common/BreadCrumb";
import Tab from "Common/Components/Tab/Tab";
import {useFormik} from "formik";
import * as Yup from "yup";
import useAuthenticatedUser from "../../Providers/AuthProvider";
import ErrorHelper from "../../Backend/Services/Helpers/ErrorHelper";
import updateUser, {UpdateUserPayload} from "../../Backend/Api/User/update-user";
import {toast, ToastContainer} from "react-toastify";
import useHandleAxiosRequest from "../../Hooks/useHandleAxiosRequest";

const Settings = () => {
    const {user} = useAuthenticatedUser();
    const [, , handleRequest] = useHandleAxiosRequest()

    const validation: any = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: user?.name ?? '',
            email: user?.email ?? '',
            password: undefined,
            role: user?.role ?? ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Por favor digite seu nome"),
            email: Yup.string().required("Por favor digite seu email"),
            password: Yup.string().nullable()
        }),

        onSubmit: (values) => {
            try {
                const valuesWithToken: UpdateUserPayload = {...values, token: user?.token ?? ''} as UpdateUserPayload;
                handleRequest(() => updateUser(valuesWithToken))
                    .then(() => {
                        toast("Usuario atualizado!", {autoClose: 2000, type: "success"})
                    });
            } catch (e) {
                ErrorHelper.handleError(e);
            }
        },
    });
    return (
        <React.Fragment>
            <BreadCrumb title='Meu Perfil' pageTitle='Usuarios'/>
            <ToastContainer closeButton={false} limit={1}/>
            <Tab.Container defaultActiveKey="personalTabs">
                <React.Fragment>
                    <div className="card">
                        <div className="card-body">
                            <h6 className="mb-1 text-15">Dados pessoais</h6>
                            <form action="#!"
                                  onSubmit={(e) => {
                                      e.preventDefault();
                                      validation.handleSubmit();
                                      return false;
                                  }}
                            >
                                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">

                                    <div className="xl:col-span-6">
                                        <label htmlFor="inputValue"
                                               className="inline-block mb-2 text-base font-medium">Nome</label>
                                        <input type="text"
                                               id="inputValueName"
                                               className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                               placeholder="Digite seu nome"
                                               name="name"
                                               value={validation.values.name || ""}
                                        />
                                        {validation.touched.name && validation.errors.name ? (
                                            <p className="text-red-400">{validation.errors.name}</p>
                                        ) : null}
                                    </div>
                                    <div className="xl:col-span-6">
                                        <label htmlFor="inputValue"
                                               className="inline-block mb-2 text-base font-medium">Email</label>
                                        <input type="text" id="inputValueEmail"
                                               className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                               placeholder="Enter your value"
                                               onChange={validation.handleChange}
                                               name="email"
                                               value={validation.values.email || ""}
                                        />
                                        {validation.touched.email && validation.errors.email ? (
                                            <p className="text-red-400">{validation.errors.email}</p>
                                        ) : null}
                                    </div>
                                    <div className="xl:col-span-6">
                                        <label htmlFor="inputValue"
                                               className="inline-block mb-2 text-base font-medium">Senha</label>
                                        <input type="password"
                                               id="inputValuePassword"
                                               className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                               placeholder="********"
                                               name="password"
                                               onChange={validation.handleChange}
                                               value={validation.values.password || ""}
                                        />
                                        {validation.touched.password && validation.errors.password ? (
                                            <p className="text-red-400">{validation.errors.password}</p>
                                        ) : null}
                                    </div>


                                </div>
                                <div className="flex justify-end mt-6 gap-x-4">
                                    <button type="submit"
                                            className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                        Atualizar
                                    </button>
                                    <button type="submit"
                                            className="text-red-500 bg-red-100 btn hover:text-white hover:bg-red-600 focus:text-white focus:bg-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:ring active:ring-red-100 dark:bg-red-500/20 dark:text-red-500 dark:hover:bg-red-500 dark:hover:text-white dark:focus:bg-red-500 dark:focus:text-white dark:active:bg-red-500 dark:active:text-white dark:ring-red-400/20">
                                        Cancelar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </React.Fragment>

            </Tab.Container>
        </React.Fragment>
    );
}

export default Settings;