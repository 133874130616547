import InputMasker from "../../Services/Helpers/InputMasker";
import getApi from "../axios/api";
import {AxiosResponse} from "axios";
import {FinancialGraphPayload} from "../../../Providers/FinancialMovementProvider";


export default async function getMovementGraphs(startDate?: Date, endDate?: Date): Promise<FinancialGraphPayload> {
    let params: any = {};
    if (startDate) {
        params.start_date = InputMasker.formatDateToApi(startDate);
    }
    if (endDate) {
        params.end_date = InputMasker.formatDateToApi(endDate);
    }
    let queryString = new URLSearchParams(params).toString();

    const result: AxiosResponse<FinancialGraphPayload> = await getApi().get('/api/finance/graphs' + (queryString ? '?' + queryString : ''));
    return result.data;
}