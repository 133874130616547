import getApi from "../axios/api";
import {AxiosResponse} from "axios";

export type Product = {
    id: number;
    name: string;
}


export default async function getProductsStatus(): Promise<Product[]> {
    const response: AxiosResponse<Product[]> = await getApi().get("/api/product/status");
    return response.data;
}