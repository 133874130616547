import React, { useEffect, useState } from 'react';
import { TotalEmployeeChart } from 'pages/Dashboards/HR/Charts';
import CountUp from 'react-countup';
import useHandleAxiosRequest from 'Hooks/useHandleAxiosRequest';
import getMovementCardGraphData from 'Backend/Api/Movement/get-graph-movement-card';
import InputMasker from 'Backend/Services/Helpers/InputMasker';

interface DashType {
    dataFinal:Date|null,
     dataInicial:Date|null,
}
const CashOutCardInformation = ({dataFinal, dataInicial}:DashType) => {

    const [data, setData] = useHandleAxiosRequest(async () => getMovementCardGraphData(dataInicial, dataFinal), [])
    const [, , handleRequest] = useHandleAxiosRequest();
    
    useEffect(() => {
        if(dataInicial == null && dataFinal == null){
            return;
        }

        handleRequest(() => getMovementCardGraphData(dataInicial, dataFinal))
            .then((response: any) => {
                setData(response);
            });
    }, [dataFinal, dataInicial])

    return (
        <React.Fragment>
            <div className="">
                <div className=" card w-full ">
                    <div className=" card col-span-12 md:col-span-3 h-[180px]">
                        <div className="card-body">
                            <div className="grid grid-cols-12">
                                <div className="col-span-8 md:col-span-9">
                                    <p className="text-slate-500 dark:text-slate-200">Valor total de despesas</p>
                                    <h5 className="mt-3 mb-4"> R$
                                        <CountUp
                                            end={data?.total_value ?? 0.00}
                                            className="counter-value"
                                            decimals={2}
                                        />
                                    </h5>
                                </div>
                                <div className="col-span-4 md:col-span-3">
                                    <TotalEmployeeChart
                                        chartId="rejectedCandidates"
                                        dataChartColor='["bg-red-500"]'
                                        series={[data?.growth_percentage ?? 0]}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center gap-3 mt-3">
                                <p className="text-slate-500 dark:text-slate-200 grow">
                                    <span
                                        style={{ color: data.growth_percentage > 0 ? 'green' : 'red' }}
                                        className="font-medium"
                                    >
                                        {InputMasker.maskPercentage(data?.growth_percentage ??0) }
                                    </span>
                                    { data.growth_percentage > 0 ? ' Aumento' : ' Queda'}
                                </p>
                                <p className="text-slate-500 dark:text-slate-200">Este Mês</p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </React.Fragment>
    );
};


export default CashOutCardInformation;



