import UserList from "../pages/Users/UserList";
import Profile from "../pages/Users/Profile";
import ProductList from "../pages/Users/ProductList";
import ProductDetails from "pages/Users/ProductDetails";
import Login from "../pages/Authentication/Login";
import ResetPassword from "../pages/Authentication/ResetPassword";
import Pages404 from "../pages/AuthenticationInner/Pages404";
import Logout from "../pages/Authentication/LogOut";
import ConfirmPassword from "../pages/Authentication/ConfirmPassword";
import FinancialMovement from "../pages/Users/FinancialMovement";
import Sales from "pages/Users/Sales";
import Dashboards from "pages/Users/Dashboards/index";
import SalesList from "../pages/Users/SalesList";
import ProductSoldList from "../pages/Users/ProductSoldList";

interface RouteObject {
    path: string;
    component: React.ComponentType<any>;
    exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
    { path: "/users/list", component: UserList },
    { path: "/users/profile", component: Profile },
    { path: "/products/list", component: ProductList },
    { path: "/products/sold", component: ProductSoldList },
    { path: "/products/details/:id", component: ProductDetails },
    { path: "/finance/movement", component: FinancialMovement },
    { path: "/sales/register", component: Sales },
    { path: "/sales/list", component: SalesList },
    { path: "/dashboards", component: Dashboards },
];

const publicRoutes: Array<RouteObject> = [
    { path: "/", component: Logout },
    { path: "/login", component: Login },
    { path: "/logout", component: Logout },
    { path: "/reset-password", component: ResetPassword },
    { path: "/user/confirm-password", component: ConfirmPassword },
    { path: "/not-found", component: Pages404 },
];

export default {authProtectedRoutes, publicRoutes};