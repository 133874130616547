import {UploadMediaResponse} from "../../../Backend/Api/Media/upload-media";
import ImageModal from "./ImageModal";
import React from "react";

export type ImageCarrouselProps = {
    images: UploadMediaResponse[],
    onDelete: (image: UploadMediaResponse) => void,
};

export default function ImageCarrousel({images, onDelete}: ImageCarrouselProps) {
    return (
        <div className='max-w-full overflow-x-scroll'>
            <div className='p-2 flex'>
                {images?.map((image: UploadMediaResponse, index: number) => (
                    <ImageModal  image={image} onDelete={onDelete} key={index}/>
                ))}
            </div>
        </div>
    )
}