import InputMasker from "../../Services/Helpers/InputMasker";
import getApi from "../axios/api";

export type CreateMovementInput = {
    type: string;
    movement_type?: string;
    description: string;
    amount: string;
    qty_repeat: number;
    is_completed: boolean|string;
    start_date: string;
}

export default async function updateMovement(input: CreateMovementInput, token: string) {
    input.is_completed = input.is_completed === 'true';
    input.movement_type = input.type;
    input.amount = InputMasker.maskMoneyWithoutRs(input.amount);
    await getApi().put('/api/finance/movement/' + token, input);
}