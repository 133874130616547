import AuthToken from "../../Services/Auth/AuthToken";
import getApi from "../axios/api";
import {User} from "../../Services/Auth/AuthUser";

export default async function getUserInfo(authToken?: string): Promise<User> {
    const token = authToken ?? AuthToken.getToken();
    if (!token) {
        throw new Error("Não autorizado!");
    }
    const result = await getApi().get("/api/auth/user-info");
    return new User(
        result.data.id,
        result.data.email,
        result.data.name,
        result.data.role,
        result.data.token,
        result.data.permissions,
    )
}