import React from 'react';
import Flatpickr from "react-flatpickr";
import {useFinancialMovement} from "../../../Providers/FinancialMovementProvider";

const FinancialMovementDateFilter = () => {
    const {dates, setDates} = useFinancialMovement();

    function lastDayOfTheMonth() {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    return (
        <React.Fragment>
            <div className="col-span-12 card">
                <div className="md:flex xl:flex 2xl:flex justify-center  p-5 sm:grid">
                    <div>
                        <Flatpickr
                            options={{
                                mode: "range",
                                dateFormat: "d M, Y",
                                locale: 'pt',
                                inline: true,
                                maxDate: lastDayOfTheMonth(),
                                onChange: (dates) => setDates(dates),
                            }}
                            value={dates}
                            placeholder="Select Date"
                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default FinancialMovementDateFilter;
