import React, {useCallback, useEffect, useMemo, useState} from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import {Link} from 'react-router-dom';
import {Dropdown} from 'Common/Components/Dropdown';
import TableContainer from 'Common/TableContainer';

// Icons
import {FileDown, FileEdit, MoreHorizontal, Plus, Search, Trash2} from 'lucide-react';
import Modal from 'Common/Components/Modal';
import DeleteModal from 'Common/DeleteModal';

import * as Yup from "yup";
import {useFormik} from "formik";
import {toast, ToastContainer} from 'react-toastify';
import useHandleAxiosRequest from "../../Hooks/useHandleAxiosRequest";
import ErrorHelper from "../../Backend/Services/Helpers/ErrorHelper";
import getProducts, {Product, ProductFilters} from "../../Backend/Api/Product/get-products";
import getProductsStatus from "../../Backend/Api/Product/get-products-status";
import updateProduct from "../../Backend/Api/Product/update-product";
import InputWithLabel from "../Components/Custom/InputWithLabel";
import InputMasker from "../../Backend/Services/Helpers/InputMasker";
import InputFileWithLabel from "../Components/Custom/InputFileWithLabel";
import uploadMedia, {UploadMediaResponse} from "../../Backend/Api/Media/upload-media";
import createProduct, {CreateProductInput} from "../../Backend/Api/Product/create-product";
import deleteProduct from "../../Backend/Api/Product/delete-product";
import AmountInputWithLabel from "../Components/Custom/AmountInputWithLabel";
import SelectInputWithLabel from "../Components/Custom/SelectInputWithLabel";
import TextAreaWithLabel from "../Components/Custom/TextAreaWithLabel";
import {FieldError, useApiErrors} from "../../Providers/ApiErrorsProvider";
import deleteMedia from "../../Backend/Api/Media/delete-media";
import ImageCarrousel from "../../Common/Components/Modal/ImageCarrousel";
import useAuthenticatedUser from "../../Providers/AuthProvider";
import CsvFormatter from "../../Backend/Services/ExportCsv/CsvFormatter";
import CsvHelper from "../../Backend/Services/ExportCsv/CsvHelper";
import CreatableSelect from "react-select/creatable";
import getProductColors from "../../Backend/Api/Product/get-product-colors";
import {ValidationError} from "./FinancialMovement/FinancialMovementList";
import useObjectState from "../../Hooks/useObjectState";
import getQtyProductsNotSold from "../../Backend/Api/Product/get-qty-products-not-sold";
import CountUp from "react-countup";


const ProductList = () => {
    const {user} = useAuthenticatedUser()
    const [productList, setProductList] = useHandleAxiosRequest(async () => getProducts(), [])
    const [filteredProducts, setFilteredProducts] = useHandleAxiosRequest(async () => getProducts(), [])
    const [productStatus,] = useHandleAxiosRequest(async () => getProductsStatus(), [])
    const [uploadImages, setUploadImages] = useState<UploadMediaResponse[]>([])
    const {hasError, errors, setErrors} = useApiErrors();
    const [, , handleRequest] = useHandleAxiosRequest();
    const [eventData, setEventData] = useState<any>();
    const [show, setShow] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [productColors, , handleGetProductColors] = useHandleAxiosRequest(() => getProductColors(), [])
    const [productNotSold, , handleGetProductsNotSold] = useHandleAxiosRequest(() => getQtyProductsNotSold(), 0)
    const [cor, setCor] = useState<string | null>(null)
    const [productFilters, setProductFilter] = useObjectState<ProductFilters>({})
    const deleteToggle = () => setDeleteModal(!deleteModal);

    const onClickDelete = (cell: any) => {
        setDeleteModal(true);
        if (cell.id) {
            setEventData(cell);
        }
    };

    function handleDownloadCsv() {
        const productsCsvFormat = CsvFormatter.formatProductToCsvObject(productList as Product[]);
        CsvHelper.handleDownloadCsv(productsCsvFormat, 'relatorio_de_veiculos.csv');
    }

    function reloadProducts() {
        handleGetProductsNotSold(() => getQtyProductsNotSold())
        handleGetProductColors(() => getProductColors())
        handleRequest(() => getProducts(productFilters))
            .then((response: any) => {
                setFilteredProducts(response)
                setProductList(response);
            });
    }

    const ColorStatus = ({item}: any) => {
        switch (item) {
            case "Em Preparação":
                return (<span
                    className="px-2.5 py-0.5 text-xs font-medium rounded border bg-yellow-400 border-transparent  dark:bg-green-500/20 dark:border-transparent inline-flex items-center status">{item}</span>);
            case "Oficina":
                return (<span
                    className="px-2.5 py-0.5 text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-550 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status">
                    {item}</span>);
            case "Aguardando Anúncios":
                return (<span
                    className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status">
                    {item}</span>);
            case "Online":
                return (<span
                    className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-blue-400 border-transparent text-white dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status">
                    {item}</span>);
            case "Devolvida":
                return (<span
                    className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-400 border-transparent text-white dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status">
                    {item}</span>);
            case "Vendida":
                return (<span
                    className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-green-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status">
                    {item}</span>);
            default:
                return (<span
                    className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status">
                    {item}</span>);
        }
    };

    const handleDelete = () => {
        if (eventData) {
            handleRequest(() => deleteProduct(eventData.token))
                .then(() => {
                    toast("Produto deletado!", {autoClose: 2000, type: "success"})
                    setDeleteModal(false);
                    reloadProducts();
                });

        }
    };

    function handleUploadDocument(file: any, fieldName: string) {
        handleRequest(() => uploadMedia(file, fieldName))
            .then((response: UploadMediaResponse) => {
                validation.setFieldValue(fieldName, response.id);
            });
    }

    function handleUploadImages(file: any) {
        handleRequest(() => uploadMedia(file, 'foto_produto'))
            .then((response: UploadMediaResponse) => {
                const updatedImageList = [...uploadImages, response];
                setUploadImages(updatedImageList);
            });
    }

    function handleDeleteImage(file: UploadMediaResponse) {
        handleRequest(() => deleteMedia(file.token))
            .then(() => {
                const updatedImageList = uploadImages.filter((image: UploadMediaResponse) => image.token !== file.token);
                setUploadImages(updatedImageList);
            });
    }

    const handleUpdateDataClick = (ele: any) => {
        setCor(ele.cor)
        setUploadImages(ele.images ?? [])
        setEventData({
            ...ele, ...{
                laudo_cautelar: ele.laudo_cautelar?.id,
                document: ele.document?.id,
                historico_manutencao: ele.historico_manutencao?.id,
                cnh: ele.cnh?.id
            }
        });
        setIsEdit(true);
        setShow(true);
    };

    const validation: any = useFormik({
        enableReinitialize: true,

        initialValues: {
            placa: eventData?.placa ?? '',
            marca: eventData?.marca ?? '',
            modelo: eventData?.modelo ?? '',
            ano: eventData?.ano ?? '',
            km: eventData?.km ?? '',
            fabricacao: eventData?.fabricacao ?? '',
            valor: eventData?.valor?.amount_string ?? '',
            valor_fipe: eventData?.valor_fipe?.amount_string ?? '',
            commission: eventData?.commission ?? '',
            product_type: eventData?.product_type?.name ?? '',
            ipva_pago: eventData?.ipva_pago,
            ticket_to_auction: eventData?.ticket_to_auction,
            acessorios: eventData?.acessorios ?? '',
            document: eventData?.document ?? '',
            laudo_cautelar: eventData?.laudo_cautelar ?? '',
            cnh: eventData?.cnh ?? '',
            historico_manutencao: eventData?.historico_manutencao ?? '',
            client_name: eventData?.client_name ?? '',
            client_phone: eventData?.client_phone ?? '',
            status_id: eventData?.status?.id ?? '',
            spare_key: eventData?.spare_key,
            manually: eventData?.manually,
        },
        validationSchema: Yup.object({
            placa: Yup.string().nullable(),
            marca: Yup.string().required("Por favor digite a marca"),
            modelo: Yup.string().required("Por favor digite o modelo"),
            status_id: Yup.string().required("Por favor escolha o status"),
            ano: Yup.string().required("Por favor digite o ano"),
            km: Yup.string().required("Por favor digite a kilometragem"),
            fabricacao: Yup.string().required("Por digite o ano de fabricação"),
            valor: Yup.string().required("Por favor o valor"),
            valor_fipe: Yup.string().required("Por favor digite o valor da tabela fipe"),
            commission: Yup.string().nullable(),
            product_type: Yup.string().required("Por favor escolha um tipo"),
            ipva_pago: Yup.boolean().required("Por favor diga se o ipva foi pago"),
            ticket_to_auction: Yup.boolean().required("Por favor diga se tem Passagem em Leilão"),
            spare_key: Yup.boolean().required("Por favor diga se e Manual"),
            manually: Yup.boolean().required("Por favor diga se tem Passagem em Leilão"),
            document: isEdit ? Yup.number() : Yup.number().required("Por favor faça o upload do documento"),
            acessorios: Yup.string().nullable(),
            client_name: Yup.string().nullable(),
            client_phone: Yup.string().nullable(),
        }),

        onSubmit: (values: any, formikHelpers: any) => {
            try {
                values.cor = cor;
                if (isEdit) {
                    const updateUserData = {
                        token: eventData ? eventData.token : '',
                        ...values,
                    } as CreateProductInput;
                    handleRequest(() => updateProduct(updateUserData, uploadImages)).then(() => {
                        toast("Produto atualizado!", {autoClose: 2000, type: "success"})
                        toggle();
                        reloadProducts();
                    }).catch(() => {
                        formikHelpers.setSubmitting(false);
                    });
                } else {
                    handleRequest(() => createProduct(values, uploadImages)).then(() => {
                        toast("Produto cadastrado com sucesso", {autoClose: 2000, type: "success"})
                        toggle();
                        reloadProducts();
                    }).catch(() => {
                        formikHelpers.setSubmitting(false);
                    });
                }
            } catch (e) {
                ErrorHelper.handleError(e);
                formikHelpers.setSubmitting(false);
            }
        },
    });

    const toggle = useCallback(() => {
        if (show) {
            setShow(false);
            setEventData("");
            setUploadImages([]);
            setIsEdit(false);
            setCor(null)
        } else {
            setShow(true);
            setEventData("");
            setUploadImages([]);
            setCor(null)
            validation.resetForm();
        }
        setErrors([]);
    }, [show, validation]);

    useEffect(() => {
        reloadProducts();
    }, [productFilters])

    const columns = useMemo(() => [
            {
                header: "ID",
                accessorKey: "id",
                enableColumnFilter: false,

                cell: (cell: any) => (
                    <Link
                        to={"/products/details/" + cell.getValue()}
                        className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"
                    >
                        {cell.getValue()}
                    </Link>
                ),
            },
            {
                header: "Status",
                accessorKey: "status.name",
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <ColorStatus item={cell.getValue()}/>
                )
            },
            {
                header: "Placa",
                accessorKey: "placa",
                enableColumnFilter: false,
            },
            {
                header: "Marca",
                accessorKey: "marca",
                enableColumnFilter: false,
            },
            {
                header: "Modelo",
                accessorKey: "modelo",
                enableColumnFilter: false,
            },
            {
                header: "Ano",
                accessorKey: "ano",
                enableColumnFilter: false,
            },
            {
                header: "Fabricação",
                accessorKey: "fabricacao",
                enableColumnFilter: false,
            },
            {
                header: "Tipo",
                accessorKey: "product_type.description",
                enableColumnFilter: false,
            },
            {
                header: "Valor",
                accessorKey: "valor.amount_string",
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <span className="text-base">R$ {cell.getValue()}</span>
                ),
            },
            {
                header: "Valor Tabela Fipe",
                accessorKey: "valor_fipe.amount_string",
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <span className="text-base">R$ {cell.getValue()}</span>
                ),
            },
            {
                header: "Diferença",
                accessorKey: "diferenca",
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <span className="text-base"
                          style={{color: cell.getValue().amount_cents > 0 ? 'red' : 'blue'}}>R$ {cell.getValue().amount_string}</span>
                ),
            },
            {
                header: "Data de criação",
                accessorKey: "created_at",
                enableColumnFilter: false,
            },
            {
                header: "Action",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => (
                    <Dropdown className="relative">
                        <Dropdown.Trigger
                            className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
                            id="usersAction1">
                            <MoreHorizontal className="size-3"/>
                        </Dropdown.Trigger>
                        <Dropdown.Content
                            placement="right-end"
                            className="absolute z-100 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600"
                            aria-labelledby="usersAction1"
                        >
                            <li>
                                <Link data-modal-target="addUserModal"
                                      className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                                      to="#!"
                                      onClick={() => {
                                          const data = cell.row.original;
                                          handleUpdateDataClick(data);
                                      }}>
                                    <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1"/> <span
                                    className="align-middle">Edit</span></Link>
                            </li>
                            {user?.canCreateProduct() && (
                                <li>
                                    <Link
                                        className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                                        to="#!" onClick={() => {
                                        const orderData = cell.row.original;
                                        onClickDelete(orderData);
                                    }}>
                                        <Trash2 className="inline-block size-3 ltr:mr-1 rtl:ml-1"/> <span
                                        className="align-middle">Delete</span></Link>
                                </li>
                            )}

                        </Dropdown.Content>
                    </Dropdown>
                ),
            }
        ], []
    );

    return (
        <React.Fragment>
            <BreadCrumb title='Listagem' pageTitle='Produtos'/>
            <DeleteModal show={deleteModal} onHide={deleteToggle} onDelete={handleDelete}/>
            <ToastContainer closeButton={false} limit={1}/>
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
                <div className="xl:col-span-12">
                    <div className="card" id="usersTable">
                        <div className="card-body">
                            <div className="flex items-center">
                                <h6 className="text-15 grow">Lista de produtos | <CountUp
                                    end={productNotSold ?? 0}
                                    decimals={0}
                                    className="counter-value"
                                /> Veiculos em estoque</h6>
                                <div className="flex items-end gap-2 w-[450px]">
                                    {user?.canCreateProduct() && (
                                        <button
                                            type="button"
                                            className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                                            onClick={toggle}
                                        >
                                            <Plus className="inline-block size-4"/>
                                            <span className="align-middle">Adicionar Produto</span>
                                        </button>
                                    )}
                                    <button
                                        type="button"
                                        onClick={() => handleDownloadCsv()}
                                        className="px-2 py-1.5 text-base text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 rounded-md  font-bold h-[40px] sm:w-full sm:mt-5 xl:w-[280px] 2xl:w-[280px] md:w-[280px] flex items-center justify-around"
                                    >
                                        Exportar Relatorio
                                        <FileDown height={'25px'}/>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
                            <form action="#!">
                                <div className="grid grid-cols-4 gap-5 xl:grid-cols-12">
                                    <div className="relative xl:col-span-2">
                                        <input
                                            type="text"
                                            className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Busque por placa"
                                            autoComplete="off"
                                            onChange={(e) => setProductFilter('placa', e.target.value)}
                                            value={productFilters.placa ?? ''}
                                        />
                                        <Search
                                            className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600"
                                        />
                                    </div>
                                    <div className="relative xl:col-span-2">
                                        <input
                                            type="text"
                                            className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Busque por marca "
                                            autoComplete="off"
                                            onChange={(e) => setProductFilter('marca', e.target.value)}
                                            value={productFilters.marca ?? ''}
                                        />
                                        <Search
                                            className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600"/>
                                    </div>
                                    <div className="relative xl:col-span-2">
                                        <input
                                            type="text"
                                            className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Busque por modelo"
                                            autoComplete="off"
                                            onChange={(e) => setProductFilter('modelo', e.target.value)}
                                            value={productFilters.modelo ?? ''}

                                        />
                                        <Search
                                            className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600"
                                        />
                                    </div>

                                    <div className="relative xl:col-span-2">
                                        <select
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            onChange={(e) => setProductFilter('status', e.target.value)}
                                            value={productFilters.status ?? ''}
                                        >
                                            <option value="" className=''>{"Busque por status"}</option>
                                            {productStatus.map((option: any, index: number) => (
                                                <option
                                                    key={index}
                                                    value={option.name}
                                                >
                                                    {option.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="relative xl:col-span-2">
                                        <select
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            onChange={(e) => setProductFilter('limit', e.target.value)}
                                            value={productFilters.limit ?? ''}
                                        >
                                            <option value="50" className=''>Limite (default 50)</option>
                                            <option value="10" className=''>10</option>
                                            <option value="20" className=''>20</option>
                                            <option value="50" className=''>50</option>
                                            <option value="100" className=''>100</option>
                                            <option value="0" className=''>Sem limite</option>

                                        </select>
                                    </div>
                                    <div className="relative xl:col-span-2">
                                        <select
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            onChange={(e) => setProductFilter('orderBy', e.target.value)}
                                            value={productFilters.orderBy ?? ''}
                                        >
                                            <option value="" className=''>Ordenar por</option>
                                            <option value="products.id" className=''>Ordenar por Id</option>
                                            <option value="products.marca" className=''>Ordenar por Marca</option>
                                            <option value="products.marca" className=''>Ordenar por Modelo</option>
                                        </select>
                                    </div>
                                    <div className="relative xl:col-span-2">
                                        <select
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            onChange={(e) => setProductFilter('orderType', e.target.value)}
                                            value={productFilters.orderType ?? ''}
                                        >
                                            <option value="" className=''>Ordenar crescente/decrescente</option>
                                            <option value="asc" className=''>Crescente</option>
                                            <option value="desc" className=''>Decrescente</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="card-body">
                            {filteredProducts && filteredProducts.length > 0 ?
                                <TableContainer
                                    isPagination={true}
                                    columns={(columns || [])}
                                    data={(filteredProducts || [])}
                                    customPageSize={20}
                                    divclassName="overflow-x-auto"
                                    tableclassName="w-full whitespace-nowrap"
                                    theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
                                    thclassName="px-3.5  first:pl-5 last:pr-5 font-semibold border-y border-slate-200 dark:border-zink-500"
                                    tdclassName="px-3.5 h-max-[5px] h-[5px] first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
                                    trclassName={'h-[5px]'}
                                    PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                                />
                                :
                                (<div className="noresult">
                                    <div className="py-6 text-center">
                                        <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20"/>
                                        <h5 className="mt-2 mb-1">Nenhum produto encontrado!</h5>
                                    </div>
                                </div>)}
                            <br/>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={show}
                onHide={toggle}
                id="defaultModal"
                modal-center="true"
                className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
                dialogClassName="w-screen md:w-[60rem] bg-white shadow rounded-md dark:bg-zink-600"
            >
                <Modal.Header
                    className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
                >
                    <Modal.Title className="text-16">{isEdit ? "Editar Produto" : "Adicionar Produto"}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="max-h-[800px] p-4 overflow-y-scroll bg-slate-200 rounded-b-md">
                    <form
                        action="#!"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        <div className='bg-white rounded-md shadow-lg mb-5'>
                            <div className='grid gap-2 grid-cols-4 p-2'>
                                <div>
                                    <label
                                        htmlFor='select-color-input'
                                        className="inline-block mb-2 text-base font-medium"
                                    >
                                        Placa
                                    </label>
                                    <CreatableSelect
                                        onChange={(data: any) => validation.setFieldValue('placa',data?.value ?? '')}
                                        value={{
                                            label: validation.values.placa ?? '',
                                            value: validation.values.placa ?? '',
                                        }}
                                        className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        id="select-color-input"
                                        data-placeholder="Selecione uma Cor"
                                        name="cor"
                                        options={[
                                            {label: 'OFFROAD', value: 'OFFROAD'}
                                        ]}

                                    />
                                    <ValidationError validation={validation} fieldName='cor'/>
                                </div>
                                <InputWithLabel
                                    validation={validation}
                                    name="marca"
                                    label='Marca'
                                    id="marcaInput"
                                    placeholder="Digite a marca do veiculo"
                                    disabled={!user?.canCreateProduct()}
                                />
                                <InputWithLabel
                                    validation={validation}
                                    name="modelo"
                                    label='Modelo'
                                    id="modeloInput"
                                    placeholder="Digite o modelo do veiculo"
                                    disabled={!user?.canCreateProduct()}
                                />
                                <SelectInputWithLabel
                                    validation={validation}
                                    name="status_id"
                                    id="status_id"
                                    label="Status"
                                    options={productStatus.map((status: any) => {
                                        return {
                                            value: status.id, description: status.name
                                        }
                                    })}
                                    disabled={!user?.canCreateProduct()}
                                />

                            </div>
                            <div
                                className={`grid gap-2 p-2 grid-cols-${eventData?.product_type === 'veiculo_consignado' ? '3' : '2'}`}>
                                <AmountInputWithLabel
                                    validation={validation}
                                    name="valor"
                                    label='Valor do veiculo'
                                    id="valueInput"
                                    disabled={!user?.canCreateProduct()}
                                />
                                <AmountInputWithLabel
                                    validation={validation}
                                    name="valor_fipe"
                                    label='Valor Tabela Fipe'
                                    id="fipeValueInput"
                                    disabled={!user?.canCreateProduct()}
                                />

                                {eventData?.product_type === 'veiculo_consignado' && (
                                    <InputWithLabel
                                        validation={validation}
                                        name="commission"
                                        label='Comissão ( % )'
                                        id="commissionId"
                                        placeholder="Comissão ( % )"
                                        mask={InputMasker.maskPercentage}
                                        disabled={!user?.canCreateProduct()}
                                    />
                                )}

                            </div>
                        </div>
                        <div className='bg-white rounded-md shadow-lg mb-5'>
                            <div className='grid gap-2 grid-cols-2 p-2'>
                                <InputWithLabel
                                    validation={validation}
                                    name="fabricacao"
                                    label='Fabricação'
                                    id="fabricationInput"
                                    placeholder="Digite o ano de fabricação"
                                    mask={InputMasker.maskYear}
                                    disabled={!user?.canCreateProduct()}
                                />
                                <InputWithLabel
                                    validation={validation}
                                    name="ano"
                                    label='Ano ( modelo )'
                                    id="yearInput"
                                    placeholder="2024"
                                    mask={InputMasker.maskYear}
                                    disabled={!user?.canCreateProduct()}
                                />
                                <InputWithLabel
                                    validation={validation}
                                    name="km"
                                    label='Km'
                                    id="kmInput"
                                    placeholder="Digite a kilometragem"
                                    mask={InputMasker.maskNumber}
                                    disabled={!user?.canCreateProduct()}
                                />
                                <div>
                                    <label
                                        htmlFor='select-color-input'
                                        className="inline-block mb-2 text-base font-medium"
                                    >
                                        Cor
                                    </label>
                                    <CreatableSelect
                                        onChange={(data: any) => setCor(data?.value ?? '')}
                                        value={{label: validation.values.cor ?? cor ?? '', value: validation.values.cor ?? cor ?? ''}}
                                        className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        id="select-color-input"
                                        data-placeholder="Selecione uma Cor"
                                        name="cor"
                                        options={productColors.map((color: string) => {
                                            return {label: color, value: color}
                                        }) as any[]}

                                    />
                                    <ValidationError validation={validation} fieldName='cor'/>
                                </div>

                            </div>

                            <div className='grid gap-2 grid-cols-3 p-2'>
                                <SelectInputWithLabel
                                    validation={validation}
                                    name="product_type"
                                    id="typeInput"
                                    label="Tipo do veiculo"
                                    options={[
                                        {value: 'veiculo_consignado', description: 'Veículo Consignado'},
                                        {value: 'veiculo_proprio', description: 'Veículo Próprio'},
                                    ]}
                                    disabled={!user?.canCreateProduct()}
                                />
                                <SelectInputWithLabel
                                    validation={validation}
                                    name="ipva_pago"
                                    id="ipvaInput"
                                    label='IPVA Pago'
                                    options={[
                                        {value: true, description: 'Sim'},
                                        {value: false, description: 'Não'},
                                    ]}
                                    disabled={!user?.canCreateProduct()}
                                />

                                <SelectInputWithLabel
                                    validation={validation}
                                    name="ticket_to_auction"
                                    id="ticket_to_auction"
                                    label='Passagem em Leilão'
                                    options={[
                                        {value: true, description: 'Sim'},
                                        {value: false, description: 'Não'},
                                    ]}
                                    disabled={!user?.canCreateProduct()}
                                />
                            </div>

                            <div className='grid gap-2 grid-cols-2 p-2'>

                                <SelectInputWithLabel
                                    validation={validation}
                                    name="spare_key"
                                    id="spare_key"
                                    label='Chave reserva'
                                    options={[
                                        {value: true, description: 'Sim'},
                                        {value: false, description: 'Não'},
                                    ]}
                                    disabled={!user?.canCreateProduct()}
                                />

                                <SelectInputWithLabel
                                    validation={validation}
                                    name="manually"
                                    id="manually"
                                    label='Manual'
                                    options={[
                                        {value: true, description: 'Sim'},
                                        {value: false, description: 'Não'},
                                    ]}
                                    disabled={!user?.canCreateProduct()}
                                />
                            </div>
                        </div>
                        {
                            validation.values?.product_type === 'veiculo_consignado' && (
                                <div className='bg-white rounded-md shadow-lg mb-5'>
                                    <div className='grid gap-2 grid-cols-2 p-2'>
                                        <InputWithLabel
                                            validation={validation}
                                            name="client_name"
                                            label='Nome do Cliente'
                                            id="clientNameInput"
                                            placeholder="Digite o nome do cliente"
                                            disabled={!user?.canCreateProduct()}
                                        />
                                        <InputWithLabel
                                            validation={validation}
                                            name="client_phone"
                                            label='Telefone do Cliente'
                                            id="clientPhoneInput"
                                            placeholder="Digite o telefone do cliente"
                                            mask={InputMasker.maskPhone}
                                            disabled={!user?.canCreateProduct()}
                                        />
                                    </div>
                                </div>
                            )
                        }

                        <div className='bg-white rounded-md shadow-lg mb-5'>
                            {
                                (
                                    <div className='grid gap-2 grid-cols-2 p-2'>
                                        <InputFileWithLabel
                                            validation={validation}
                                            name="document"
                                            label='CRLV-e'
                                            setValue={(file) => handleUploadDocument(file, 'document')}
                                            isUploaded={validation.values?.document ?? null}
                                            disabled={!user?.canCreateProduct()}
                                        />
                                        <InputFileWithLabel
                                            validation={validation}
                                            name="laudo_cautelar"
                                            label='Laudo Cautelar'
                                            setValue={(file) => handleUploadDocument(file, 'laudo_cautelar')}
                                            isUploaded={validation.values?.laudo_cautelar ?? null}
                                            disabled={!user?.canCreateProduct()}
                                        />
                                        <InputFileWithLabel
                                            validation={validation}
                                            name="cnh"
                                            label='CNH'
                                            setValue={(file) => handleUploadDocument(file, 'cnh')}
                                            isUploaded={validation.values?.cnh ?? null}
                                            disabled={!user?.canCreateProduct()}
                                        />
                                        <InputFileWithLabel
                                            validation={validation}
                                            name="historico_manutencao"
                                            label='Histórico de Manutenção'
                                            setValue={(file) => handleUploadDocument(file, 'historico_manutencao')}
                                            isUploaded={validation.values?.historico_manutencao ?? null}
                                            disabled={!user?.canCreateProduct()}
                                        />
                                    </div>
                                )
                            }
                            <div className='p-2'>
                                <TextAreaWithLabel
                                    validation={validation}
                                    name="acessorios"
                                    label='Acessorios'
                                    id="accessoriesInput"
                                    placeholder="Digite os acessorios do veiculo"
                                    defaultRows={5}
                                    disabled={!user?.canCreateProduct()}
                                />
                            </div>
                        </div>
                        <div className='bg-white rounded-md shadow-lg mb-5 max-w-full'>
                            <ImageCarrousel images={uploadImages} onDelete={handleDeleteImage}/>

                            <div className='p-2'>
                                <InputFileWithLabel
                                    validation={validation}
                                    name="documento"
                                    label='Faça o upload das fotos do Veiculo'
                                    setValue={(file) => handleUploadImages(file)}
                                    isUploaded={validation.values?.documento ?? null}
                                />
                            </div>
                        </div>

                        <div className="  rounded-md bg-white mb-10">
                            {hasError() && (
                                <div className='p-2'>
                                    {errors.map((error: FieldError) => (
                                        error.errors.map((message: string, index: number) => (
                                            <p key={index} className="text-red-400">{message}</p>
                                        ))
                                    ))}
                                </div>
                            )}
                            <div className='grid gap-5 grid-cols-2 p-2'>
                                <button
                                    type="reset"
                                    data-modal-close="addDocuments"
                                    className="text-red-500 shadow transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                                    onClick={toggle}
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="submit"
                                    className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                                    onClick={validation.handleSubmit}
                                >
                                    {isEdit ? "Atualizar Produto" : "Adicionar Produto"}
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default ProductList;
