import getApi from "../axios/api";
import {AxiosResponse} from "axios";

export type Amount = {
    amount_cents: number;
    amount_string: string;
}
export type ProductType = {
    id: number;
    name: string;
    description: string;
}

export type ProductStatus = {
    id: number;
    name: string;
}

export type Product = {
    id: number;
    marca: string;
    modelo: string;
    token: string;
    ano: number;
    placa: string;
    cor: string;
    km: number;
    fabricacao: number;
    valor: Amount;
    valor_fipe: Amount;
    commission: number;
    diferenca: Amount;
    value_with_profit_margin: Amount;
    profit_margin: 0;
    product_type: ProductType;
    created_by: number;
    ipva_pago: boolean;
    ticket_to_auction: boolean;
    manually: boolean;
    spare_key: boolean;
    acessorios?: string;
    status?: ProductStatus;
    images: any[];
}

export type ProductFilters = {
    placa?: string;
    marca?: string;
    modelo?: string;
    status?: string;
    limit?: string;
    orderBy?: string;
    orderType?: string;
}

export default async function getProducts(filters?: ProductFilters): Promise<Product[]> {
    let queryString = new URLSearchParams(filters ?? {}).toString();
    const response: AxiosResponse<Product[]> = await getApi().get("/api/product"+ (queryString ? '?'+ queryString : ''));
    return response.data;
}