import React from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import FinancialMovementWidgets from "./FinancialMovement/FinancialMovementWidgets";
import FinancialMovementList from "./FinancialMovement/FinancialMovementList";
import { FinancialMovementProvider } from "../../Providers/FinancialMovementProvider";
import { ToastContainer } from "react-toastify";
import CashOutLeads from "./Dashboards/CashOutLeads";

const FinancialMovement = () => {
    return (
        <React.Fragment>
            <BreadCrumb title='Movimentação Financeira' pageTitle='Financeiro' />
            <ToastContainer closeButton={false} limit={1} />
            <FinancialMovementProvider>
                <div className="">
                    <FinancialMovementWidgets />
                </div>
                <div className="grid grid-cols-12 2xl:grid-cols-12 gap-x-5">
                    <div className="col-span-12 md:col-span-8">
                        <FinancialMovementList />
                    </div>

                    <div className="col-span-12 md:col-span-4">
                        <CashOutLeads />
                    </div>
                </div>
            </FinancialMovementProvider>
        </React.Fragment>
    );
};

export default FinancialMovement;
