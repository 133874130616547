export default class CsvHelper {
    static handleDownloadCsv(array: any[], filename: string) {
        const csvContent = this.convertArrayToCSV(array);
        const blob = this.createCSVBlob(csvContent);
        CsvHelper.downloadCSV(blob, filename);
    }

    static downloadCSV(blob: any, filename: string) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }
    static convertArrayToCSV(array: any[]): string {
        if (array.length === 0) {
            return '';
        }

        const keys = Object.keys(array[0]);
        const header = keys.join(',');
        const rows = array.map(obj => keys.map(key => obj[key]).join(','));

        return [header, ...rows].join('\n');
    }

    static createCSVBlob(csvContent: string) {
        return new Blob([csvContent], { type: 'text/csv' });
    }
}