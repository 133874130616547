import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import useHandleAxiosRequest from "../../Hooks/useHandleAxiosRequest";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import confirmPassword from "../../Backend/Api/Auth/confirm-password";

const ConfirmPassword = () => {

    document.title = "Westside | Confirmar senha";

    const [,, handleRequest] = useHandleAxiosRequest();
    const navigate = useNavigate();
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if(!code) {
            navigate('/login');
        }
        setCode(code ?? '');

    });
    function handleConfirmPassword() {
        if(!password || !repeatPassword) {
            return;
        }
        if(password !== repeatPassword) {
            toast.error("As senhas não conferem");
            return;
        }
        handleRequest(() => confirmPassword(code, password))
            .then(() => {
                toast.success("Senha alterada com sucesso");
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            })
            .catch(() => {

            });
    }

    React.useEffect(() => {
        const bodyElement = document.body;

        bodyElement.classList.add('flex', 'items-center', 'justify-center', 'min-h-screen', 'py-16', 'lg:py-10', 'bg-slate-50', 'dark:bg-zink-800', 'dark:text-zink-100', 'font-public');

        return () => {
            bodyElement.classList.remove('flex', 'items-center', 'justify-center', 'min-h-screen', 'py-16', 'lg:py-10', 'bg-slate-50', 'dark:bg-zink-800', 'dark:text-zink-100', 'font-public');
        }
    }, []);

    return (
        <React.Fragment>
            <ToastContainer/>
            <div className="relative">
                <div className="mb-0 w-screen lg:w-[500px] card shadow-lg border-none shadow-slate-100 relative">
                    <div className="!px-10 !py-12 card-body">
                        <Link to="/">
                            <img src='/images/logo.jpeg' alt="" className="mx-auto"
                                 style={{width: '200px', borderRadius: '50px'}}/>
                        </Link>

                            <div className="mt-8 text-center">
                                <h4 className="mb-1 text-custom-500 dark:text-custom-500">Confirme sua senha</h4>
                            </div>
                        <form action="/" className="mt-10" id="registerForm"
                              onSubmit={(event: any) => {
                                  event.preventDefault();
                              }}>
                                    <div className="mb-3">
                                        <label htmlFor="password-field"
                                               className="inline-block mb-2 text-base font-medium">Senha</label>
                                        <input
                                            type="text"
                                            id="password-field"
                                            name="password"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Digite sua senha"
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}/>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="confirm-password-field"
                                               className="inline-block mb-2 text-base font-medium">Repetir Senha</label>
                                        <input
                                            type="text"
                                            id="confirm-password-field"
                                            name="password"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Repetir senha"
                                            onChange={(e) => setRepeatPassword(e.target.value)}
                                            value={repeatPassword}/>
                                    </div>
                                <div className="mt-10">
                                    <button
                                        type="button"
                                        className="w-full text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                                        onClick={() => handleConfirmPassword()}
                                    >
                                        Confirmar Senha
                                    </button>
                                </div>

                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ConfirmPassword;