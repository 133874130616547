import React from "react";

export type InputFileWithLabelProps = {
    name: string;
    label: string;
    validation: any;
    setValue: (value: any) => any;
    isUploaded?: boolean;
    disabled?: boolean;
}

export default function InputFileWithLabel({validation, name, label, setValue, isUploaded, disabled}: InputFileWithLabelProps) {
    return (
        <div className="mb-3">
            <label
                htmlFor={name}
                className="inline-block mb-2 text-base font-medium"
            >
                {label}
            </label>
            <input
                disabled={disabled}
                type='file'
                id={name}
                className={
                "form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                + (isUploaded ? " bg-green-50" : "")}
                onChange={(e) => setValue(e.target.files?.[0])}
            />
            {(validation.touched.hasOwnProperty(name) ?? null) && (validation.errors.hasOwnProperty(name) ?? null) ? (
                <p className="text-red-400">{validation.errors[name]}</p>
            ) : null}
        </div>
    )
}