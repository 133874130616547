import React from "react";

import useAuthenticatedUser from "../../Providers/AuthProvider";

const Logout: React.FC = () => {
    document.title = "Logout | Westside Motorcicle";
    const {logout} = useAuthenticatedUser();

    React.useEffect(() => {
        logout();
    }, []);

    return <></>;
}

export default Logout;
