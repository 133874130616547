import InputMasker from "../../Services/Helpers/InputMasker";
import getApi from "../axios/api";
import {AxiosResponse} from "axios";


export default async function getMovementCardGraphData(startDate?: Date|null, endDate?: Date|null): Promise<any[]> {
    let params: any = {};
    if (startDate) {
        params.start_date = InputMasker.formatDateToApi(startDate);
    }
    if (endDate) {
        params.end_date = InputMasker.formatDateToApi(endDate);
    }
    let queryString = new URLSearchParams(params).toString();

    const result: AxiosResponse<any[]> = await getApi().get('/api/finance/graph-movement' + (queryString ? '?' + queryString : ''));
    return result.data;
}