import getApi from "../axios/api";

export type UpdateUserPayload = {
    token: string;
    name: string;
    email: string;
    role: string;
    password?: string;
}

export default async function updateUser(payload: UpdateUserPayload): Promise<void> {
    await getApi().put("/api/user", payload);
}
