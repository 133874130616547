import React from "react";

import { MoveDownLeft } from 'lucide-react';
import SimpleBar from 'simplebar-react';
import { useFinancialMovement } from "../../../Providers/FinancialMovementProvider";
import InputMasker from "../../../Backend/Services/Helpers/InputMasker";
import MovementStatus from "../FinancialMovement/MovementStatus";

const CashOutLeads = () => {
    const {movementList } = useFinancialMovement();
    return (
        <React.Fragment>
            <div className="col-span-12 md:order-12 lg:col-span-12 xl:col-span-8 2xl:col-span-3">
                <div className="grid grid-cols-12 gap-x-5 h-[520px]">
                    <div className="col-span-12 card">
                        <div className="!pb-0 card-body">
                            <div className="flex items-center gap-2 mb-3">
                                <h6 className="text-15 grow">Principais saidas</h6>
                            </div>
                        </div>
                        <div className="pb-5"  style={{overflowY: 'scroll', maxHeight: '600px'}}>
                            <SimpleBar className="flex flex-col h-[100%] gap-4 px-5">
                                <div className="flex flex-col gap-3">
                                    {(movementList)
                                        .filter((item: any) => item.type === 'cash_out')
                                        .sort((a, b) => b.amount - a.amount)
                                        .map((item: any, key: number) => (
                                        <div className="flex flex-wrap items-center gap-3" key={key}>
                                            <div
                                                className="flex items-center justify-center size-6 text-red-500 rounded-full shrink-0">
                                                <MoveDownLeft className="size-4"/>
                                            </div>
                                            <div className="grow">
                                                <h6 className="mb-0">{item.description?.substring(0, 9) + '...'}</h6>
                                            </div>
                                            <div className="shrink-0">
                                                <h6>{InputMasker.maskMoney(item.amount)}</h6>
                                            </div>
                                            <div className="w-20 ltr:text-right rtl:text-left shrink-0">
                                                <MovementStatus is_completed={item.is_completed} type={item.type}/>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </SimpleBar>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CashOutLeads;
