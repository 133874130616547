import getApi from "../../Api/axios/api";
import {User} from "./AuthUser";

export default class AuthToken {
    private static token: string | null = null;
    static getToken(): string | null {
        if (this.token) {
            return this.token;
        }
        return localStorage.getItem("authToken");
    }

    static setToken(token: string) {
        this.token = token;
        localStorage.setItem("authToken", token);
    }

    static removeToken() {
        this.token = null;
        localStorage.removeItem("authToken");
    }

    static async validate(): Promise<User> {
        const result = await getApi().get("/api/auth/user-info");
        return result.data;
    }
}