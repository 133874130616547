import { useState, Dispatch, SetStateAction } from 'react';

// Função auxiliar para atualizar valores aninhados
const setNestedValue = (obj: any, path: string, value: any): any => {
    const keys = path.split('.');
    let tempObj = obj;
    for (let i = 0; i < keys.length - 1; i++) {
        if (!tempObj[keys[i]]) {
            tempObj[keys[i]] = {};
        }
        tempObj = tempObj[keys[i]];
    }
    tempObj[keys[keys.length - 1]] = value;
    return { ...obj };
};

export default function useObjectState<T>(initialState: T): [T, (path: string, value: any) => void] {
    const [state, setState] = useState<T>(initialState);

    const setObjectValue = (path: string, value: any) => {
        setState(prevState => setNestedValue(prevState, path, value));
    };

    return [state, setObjectValue];
}

