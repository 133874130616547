import {toast} from "react-toastify";

export default class ErrorHelper {
    static handleError(error: any, history: any = null): void {
        toast(error.response?.data?.message ?? error.message, { autoClose: 5000, type: "error"});
        setInterval(() => {
            if(error.response?.status === 401 && history) {
                history("/login");
            }
        }, 2000);
    }
}