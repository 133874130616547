import React, { useState } from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import Sales from './Sales';
import CashOut from './CashOut';
import Sellers from './Sellers';
import SalesCardInformation from './SalesCardInformation';
import CashOutCardInformation from './CashOutCardInformation';
import PatioCost from './PatioCost';

import Flatpickr from "react-flatpickr";

const Analytics = () => {


  const [dataInicial, setDataInicial] = useState<Date|null>(null);
  const [dataFinal, setDataFinal] = useState<Date|null>(null);

  return (
    <React.Fragment>
      <BreadCrumb title='Dashboard' pageTitle='Dashboards' />
      <div className="grid grid-cols-12 gap-x-5">
        <div className='grid col-span-12 card w-full'>
          <div className="">
            <div className="card-body">
              <div className='flex justify-between gap-5'>
                <div className='w-1/2'>
                  <h6 className="mb-1 text-15">Data inicial</h6>
                  <Flatpickr
                    options={{
                      dateFormat: "d/m/Y",
                    }}
                    onChange={(e)=> setDataInicial(new Date(e[0]))}
                    defaultValue={new Date().toLocaleDateString()}
                    placeholder="Data inicial"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  />
                </div>
                <div className='w-1/2'>
                  <h6 className="mb-1 text-15">Data final</h6>
                  <Flatpickr
                    options={{
                      dateFormat: "d/m/Y",
                    }}
                    defaultValue={new Date().toLocaleDateString()}
                    onChange={(e)=> setDataFinal(new Date(e[0]))}
                    placeholder="Data inicial"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  />
                </div>
                <div>

                </div>
              </div>


            </div>
          </div>
        </div>
        <div className="grid col-span-12 md:col-span-4">
          <SalesCardInformation  dataFinal={dataFinal} dataInicial={dataInicial}/>
        </div>
        <div className="grid col-span-12 md:col-span-4">
          <CashOutCardInformation dataFinal={dataFinal} dataInicial={dataInicial}/>
        </div>
        <div className="grid col-span-12 md:col-span-4">
          <PatioCost dataFinal={dataFinal} dataInicial={dataInicial}/>
        </div>
        <div className="grid col-span-12 md:col-span-6">
          <Sales dataFinal={dataFinal} dataInicial={dataInicial}/>
        </div>
        <div className="grid col-span-12 md:col-span-6">
          <CashOut dataFinal={dataFinal} dataInicial={dataInicial}/>
        </div>

        <div className="grid col-span-12 md:col-span-12">
          <Sellers dataFinal={dataFinal} dataInicial={dataInicial}/>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Analytics;
