import getApi from "../axios/api";
import {AxiosResponse} from "axios";

export type QtyProductsNotSold = {
    qty_not_sold: number;
}

export default async function getQtyProductsNotSold(): Promise<number> {
    const response: AxiosResponse<QtyProductsNotSold> = await getApi().get("/api/product/filters/qty-not-sold");
    return response.data.qty_not_sold;
}