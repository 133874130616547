import React from "react";
import InputMasker from "../../../Backend/Services/Helpers/InputMasker";

export type InputWithLabelProps = {
    validation: any;
    name: string;
    label: string;
    id: string;
    disabled?: boolean;
}

export default function AmountInputWithLabel({validation, name, label, id, disabled}: InputWithLabelProps) {
    return (
        <div className="mb-3">
            <label htmlFor={id}
                   className="inline-block mb-2 text-base font-medium"
            >{label}
            </label>

            <div className="flex items-center">
                <span
                    className="inline-block px-3 py-2 border ltr:border-r-0 rtl:border-l-0 border-slate-200 dark:border-zink-500 ltr:rounded-l-md rtl:rounded-r-md"
                >$
            </span>
                <input
                    disabled={disabled}
                    type="text"
                    id={id}
                    name={name}
                    className="ltr:rounded-l-none rtl:rounded-r-none form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="00.00"
                    onChange={validation.handleChange}
                    value={(validation.values?.[name] ?? null) ? InputMasker.maskMoneyWithoutRs(validation.values[name]) : ''}
                />
            </div>

            {(validation.touched.hasOwnProperty(name) ?? null) && (validation.errors.hasOwnProperty(name) ?? null) ? (
                <p className="text-red-400">{validation.errors[name]}</p>
            ) : null}
        </div>
    )
}