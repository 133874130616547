import React from 'react';
import {Route, Routes} from 'react-router-dom';
import routes from './routes'
import Layout from 'Layout';
import NonAuthLayout from "Layout/NonLayout"
import AuthProtected from './AuthProtected';
import {AuthProvider} from "../Providers/AuthProvider";
import {ApiErrorsProvider} from "../Providers/ApiErrorsProvider";
import Pages404 from "../pages/AuthenticationInner/Pages404";
import env from "../env";
import {authProtectedRoutes, publicRoutes} from "./allRoutes";

const RouteIndex = () => {
    return (
        <React.Fragment>
            <Routes>
                {routes.authProtectedRoutes.map((route: any, idx: number) => (
                    <Route
                        key={idx}
                        path={route.path}
                        element={
                            <ApiErrorsProvider>
                                <AuthProvider>
                                    <AuthProtected>
                                        <Layout>
                                            <route.component/>
                                        </Layout>
                                    </AuthProtected>
                                </AuthProvider>
                            </ApiErrorsProvider>
                        }
                    />
                ))}
                {routes.publicRoutes.map((route: any, idx: number) => (
                    <Route
                        path={route.path}
                        key={idx}
                        element={
                            <ApiErrorsProvider>
                                <NonAuthLayout>
                                    <AuthProvider>
                                        <route.component/>
                                    </AuthProvider>
                                </NonAuthLayout>
                            </ApiErrorsProvider>
                        }/>
                    ))}
                {
                    env.developmentMode && (
                        <>
                            {authProtectedRoutes.map((route: any, idx: number) => (
                                <Route
                                    key={idx}
                                    path={route.path}
                                    element={
                                        <ApiErrorsProvider>
                                            <AuthProvider>
                                                <AuthProtected>
                                                    <Layout>
                                                        <route.component/>
                                                    </Layout>
                                                </AuthProtected>
                                            </AuthProvider>
                                        </ApiErrorsProvider>
                                    }
                                />
                            ))}
                            {publicRoutes.map((route: any, idx: number) => (
                                <Route
                                    path={route.path}
                                    key={idx}
                                    element={
                                        <NonAuthLayout>
                                            <ApiErrorsProvider>
                                                <AuthProvider>
                                                    <route.component/>
                                                </AuthProvider>
                                            </ApiErrorsProvider>
                                        </NonAuthLayout>
                                    }/>
                            ))}
                        </>
                    )
                }
                <Route
                    path='*'
                    element={
                        <Pages404/>
                    }
                />
            </Routes>
        </React.Fragment>
    );
};


export default RouteIndex;
