import getApi from "../axios/api";
import {AxiosResponse} from "axios";

export type UploadMediaResponse = {
    id: number;
    token: string;
    url: string;
    system_path: string;
    extension: string;
}
export default async function uploadMedia(file: any, mediaType: string): Promise<UploadMediaResponse> {
    const formData = new FormData();
    formData.append("file", file);
    const response: AxiosResponse<UploadMediaResponse> = await getApi().post("/api/media?type="+ mediaType, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    });
    return response.data;
}