



import React, { useEffect, useState } from 'react';
import { TotalEmployeeChart } from 'pages/Dashboards/HR/Charts';
import CountUp from 'react-countup';
import useHandleAxiosRequest from 'Hooks/useHandleAxiosRequest';
import { Info, Wallet2 } from 'lucide-react';
import getPatioCostCardGraphData from 'Backend/Api/Movement/get-graph-patio-cost-card';

interface DashType {
    dataFinal: Date|null,
     dataInicial:Date|null,
}
const PatioCost = ({dataFinal, dataInicial}:DashType) => {

    const [showTooltip, setShowTooltip] = useState(false);
    const [data, , handleRequest] = useHandleAxiosRequest(async () => getPatioCostCardGraphData(dataInicial, dataFinal), [])
    
    useEffect(() => {
        if(dataInicial == null && dataFinal == null){
            return;
        }

        handleRequest(() => getPatioCostCardGraphData(dataInicial, dataFinal))

    }, [dataFinal, dataInicial])

    return (
        <React.Fragment>
            <div className="">
                <div className=" card w-full ">
                    <div className="card col-span-12 md:col-span-3 h-[180px]">
                        <div className="text-center card-body">
                            <div
                                className="bg-red-100 flex items-center justify-center mx-auto rounded-full size-14 bg-custom-100 text-custom-500 dark:bg-custom-500/20">
                                <Wallet2 color='red' />
                            </div>
                            <h5 className="mt-4 mb-2">R$
                                <CountUp
                                    end={data?.total_value ?? 0.00}
                                    decimals={2}
                                    className="counter-value"
                                />
                            </h5>
                            <p className="text-slate-500 dark:text-zink-200">
                                <span className="inline-flex items-center">
                                    Custo de pátio{' '}
                                    <span
                                        className="relative inline-block px-1"
                                        onMouseEnter={() => setShowTooltip(true)}
                                        onMouseLeave={() => setShowTooltip(false)}
                                    >
                                        <Info className="size-4" />
                                        {showTooltip && (
                                            <span className="tooltip absolute bottom-0 left-0 mt-2 -ml-20 bg-black text-white text-sm rounded shadow">
                                                Valor total das despesas fixas daquele período dividido pelo número de motos no pátio
                                            </span>
                                        )}
                                    </span>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>


            </div>
        </React.Fragment>
    );
};


export default PatioCost;



