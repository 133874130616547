import React, { useEffect, useState } from 'react';
import { TotalEmployeeChart } from 'pages/Dashboards/HR/Charts';
import CountUp from 'react-countup';
import useHandleAxiosRequest from 'Hooks/useHandleAxiosRequest';
import getMovementSalesGraphData from 'Backend/Api/Sales/get-movement-graph-sales';
import InputMasker from 'Backend/Services/Helpers/InputMasker';

interface DashType {
    dataFinal: Date | null,
    dataInicial: Date | null,
}

const SalesCardInformation = ({ dataFinal, dataInicial }: DashType) => {
    const [data,, handleRequest] = useHandleAxiosRequest(async () => getMovementSalesGraphData(dataInicial, dataFinal), [])

    useEffect(() => {
        if(dataInicial == null && dataFinal == null){
            return;
        }

        handleRequest(() => getMovementSalesGraphData(dataInicial, dataFinal))

    }, [dataFinal, dataInicial])

    return (
        <React.Fragment>
            <div className="">
                <div className=" card w-full h-[180px] ">
                    <div className="card-body">
                        <div className="grid grid-cols-12">
                            <div className="col-span-8 md:col-span-9">
                                <p className="text-slate-500 dark:text-slate-200">Valor total de vendas</p>
                                <h5 className="mt-3 mb-4"> R$
                                    <CountUp
                                        end={data?.total_value ?? 0}
                                        className="counter-value"
                                        decimals={2}
                                    />
                                </h5>
                            </div>
                            <div className="col-span-4 md:col-span-3">
                                <TotalEmployeeChart
                                    chartId="hiredCandidates"
                                    dataChartColor='["bg-green-500"]'
                                    series={[data?.growth_percentage ?? 0]}
                                />
                            </div>
                        </div>
                        <div className="flex items-center gap-3 mt-3">
                            <p className="text-slate-500 dark:text-slate-200 grow">
                                <span
                                    style={{ color: data.growth_percentage > 0 ? 'green' : 'red' }}
                                    className="font-medium"
                                >
                                    {InputMasker.maskPercentage(data.growth_percentage)}
                                </span>
                                {data.growth_percentage > 0 ? ' Aumento' : ' Queda'}
                            </p>
                            <p className="text-slate-500 dark:text-slate-200">Este Mês</p>
                        </div>
                    </div>
                </div>


            </div>
        </React.Fragment>
    );
};


export default SalesCardInformation;



