import {Context, createContext, useContext, useEffect, useState} from "react";
import useHandleAxiosRequest from "../Hooks/useHandleAxiosRequest";
import getMovementList from "../Backend/Api/Movement/get-movement-list";
import getMovementGraphs from "../Backend/Api/Movement/get-movement-graphs";

export type FinancialMovementContextType = {
};

export const FinancialMovementContext: Context<any> = createContext({

});

export const FinancialMovementProvider = ({children}: any) => {
    const [financialGraphs, setFinancialGraphs] = useState<FinancialGraphPayload|null>(null);
    const [movementList, setMovementList] = useState<FinancialMovement[]>([]);
    const [,,executeRequest] = useHandleAxiosRequest();

    useEffect(() => {
        executeRequest(getMovementList).then((response: FinancialMovement[]) => {
            setMovementList(response);
        });

        executeRequest(getMovementGraphs).then((response: FinancialGraphPayload) => {
            setFinancialGraphs(response);
        });
    }, []);

    return (
        <FinancialMovementContext.Provider value={{
            financialGraphs,
            setFinancialGraphs,
            movementList,
            setMovementList
        }}>
            {children}
        </FinancialMovementContext.Provider>
    );
}

export type UseFinancialMovementType = FinancialMovementContextType & {
    financialGraphs: FinancialGraphPayload|null;
    movementList: FinancialMovement[];
    dates: Date[];
    setDates: (dates: Date[]) => void;
    reloadFinance: () => void;
}

export function useFinancialMovement (): UseFinancialMovementType {
    const context = useContext(FinancialMovementContext);
    const [dates, setDates] = useState([firstDateOfMonth(), lastDayOfTheMonth()]);
    const [,,executeRequest] = useHandleAxiosRequest();

    function reloadFinance() {
        executeRequest(() => getMovementList(dates[0], dates[1])).then((response: FinancialMovement[]) => {
            context.setMovementList(response);
        });

        executeRequest(() => getMovementGraphs(dates[0], dates[1])).then((response: FinancialGraphPayload) => {
            context.setFinancialGraphs(response);
        });
    }

    if (!context) {
        throw new Error("useFinancialMovement must be used within a FinancialMovementContext");
    }

    useEffect(() => reloadFinance(), [dates]);

    function firstDateOfMonth() {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    function lastDayOfTheMonth() {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    return {
        ...context,
        dates,
        setDates,
        reloadFinance
    } as UseFinancialMovementType;
}

export type FinancialGraphBalance = {
    current_amount: number;
    pending_cash_out: number;
};

export type FinancialGraphMovement = {
    qty: number;
    percentage: number;
    monthly_growth_percentage: number;
    monthly_growth_percentage_type: string;
}

export type FinancialGraphPayload = {
    balance: FinancialGraphBalance;
    movements: {
        cashin: FinancialGraphMovement
        cashout: FinancialGraphMovement
    }
}

export type FinancialMovement = {
    token: string;
    type: string;
    description: string;
    amount: number;
    date: string;
    is_completed: boolean;
}