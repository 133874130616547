import React, {useCallback, useEffect, useMemo, useState} from 'react';
import TableContainer from 'Common/TableContainer';
import {Link} from 'react-router-dom';

// Icons
import {FileDown, MoveDownLeft, MoveUpRight, Pencil, Search, Trash2} from 'lucide-react';
import MovementStatus from "./MovementStatus";
import {FinancialMovement, useFinancialMovement} from "../../../Providers/FinancialMovementProvider";
import InputMasker from "../../../Backend/Services/Helpers/InputMasker";
import Modal from "../../../Common/Components/Modal";
import {toast} from "react-toastify";
import {useFormik} from "formik";
import * as Yup from "yup";
import ErrorHelper from "../../../Backend/Services/Helpers/ErrorHelper";
import useHandleAxiosRequest from "../../../Hooks/useHandleAxiosRequest";
import Flatpickr from "react-flatpickr";
import createMovement from "../../../Backend/Api/Movement/create-movement";
import updateMovement from "../../../Backend/Api/Movement/update-movement";
import DeleteModal from "../../../Common/DeleteFinanceModal";
import deleteMovement from "../../../Backend/Api/Movement/delete-movement";
import SelectInputWithClassNameLogic from "../../Components/Forms/SelectInputWithClassNameLogic";
import CsvFormatter from "../../../Backend/Services/ExportCsv/CsvFormatter";
import CsvHelper from "../../../Backend/Services/ExportCsv/CsvHelper";

export function ValidationError({validation, fieldName}: { validation: any, fieldName: string }) {
    if (!validation.touched?.[fieldName] || !validation.errors?.[fieldName]) {
        return <></>;
    }
    return <p className="text-red-400">{validation.errors?.[fieldName]}</p>
}

const FinancialMovementList = () => {
    const {movementList, reloadFinance} = useFinancialMovement();
    const [, , handleRequest] = useHandleAxiosRequest();
    const [eventData, setEventData] = useState<any>();
    const [data, setData] = useState<any>();

    const [show, setShow] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [isUniqueMovement, setIsUniqueMovement] = useState<boolean>(true);

    function handleDownloadCsv() {
        const saleCsvFormat = CsvFormatter.formatFinancialMovementToCsvObject(movementList as FinancialMovement[]);
        CsvHelper.handleDownloadCsv(saleCsvFormat, 'relatorio_de_movimentacao_financeira.csv');
    }

    const filterSearchData = (e: any) => {
        const search = e.target.value;
        const newData = movementList.filter((item: any) => {
            return item.description.toLowerCase().includes(search.toLowerCase());
        });
        setData(newData);
    };

    useEffect(() => setData(movementList), [movementList]);

    const handleDelete = () => {
        if (eventData) {
            handleRequest(() => deleteMovement(eventData.token))
                .then(() => {
                    toast("Movimentação excluida!", {autoClose: 2000, type: "success"})
                    setDeleteModal(false);
                    setEventData(null);
                    reloadFinance();
                });
        }
    };

    const handleUpdateDataClick = (ele: any) => {
        setIsUniqueMovement(ele.qty_repeat == 1)
        setStartDate(InputMasker.formatApiDateToDateClass(ele.date));
        setEventData({...ele});
        setIsEdit(true);
        setShow(true);
    };

    const validation: any = useFormik({
        enableReinitialize: true,

        initialValues: {
            type: eventData?.type ?? 'cash_in',
            amount: eventData?.amount ?? '',
            description: eventData?.description ?? '',
            qty_repeat: isUniqueMovement ? 1 : (eventData?.qty_repeat ?? 1),
            is_completed: eventData?.is_completed ?? false,
            start_date: eventData?.start_date ?? '',
            cashout_type: eventData?.cashout_type ?? '',
        },
        validationSchema: Yup.object({
            type: Yup.string().required("Por favor escolha um tipo de movimentação"),
            amount: Yup.string().required("Por favor digite o valor da movimentação"),
            description: Yup.string().required("Por favor de uma descrição"),
            qty_repeat: Yup.number().required("Por favor escolha a quantidade de repetições"),
            is_completed: Yup.boolean().required("Por favor escolha um status"),
            cashout_type: Yup.string().nullable(),
        }),

        onSubmit: (values, formikHelpers: any) => {
            try {
                values.start_date = InputMasker.formatDateToApi(startDate);
                if (isEdit) {
                    handleRequest(() => updateMovement(values, eventData.token)).then(() => {
                        toast((values.type === 'cash_in' ? 'Receita' : 'Despesa') + " cadastrada com sucesso", {
                            autoClose: 2000,
                            type: "success"
                        })
                        toggle();
                        reloadFinance();
                        formikHelpers.setSubmitting(false);
                    }).catch(() => {
                        formikHelpers.setSubmitting(false);
                    });
                } else {
                    handleRequest(() => createMovement(values)).then(() => {
                        toast((values.type === 'cash_in' ? 'Receita' : 'Despesa') + " cadastrada com sucesso", {
                            autoClose: 2000,
                            type: "success"
                        })
                        toggle();
                        reloadFinance();
                        formikHelpers.setSubmitting(false);
                    }).catch(() => {
                        formikHelpers.setSubmitting(false);
                    });
                }
            } catch (e) {
                ErrorHelper.handleError(e);
            }
        },
    });

    const toggle = useCallback(() => {
        if (show) {
            setShow(false);
            setEventData(null);
            setIsEdit(false);
        } else {
            setShow(true);
            setEventData(null);
            setStartDate(new Date());
            validation.resetForm();
        }
    }, [show, validation]);

    const columns = useMemo(() => [
            {
                header: "tipo",
                accessorKey: "type",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => (
                    cell.getValue() === "cash_out" ?
                        <div className="flex items-center justify-center size-6 text-red-500 rounded-full shrink-0">
                            <MoveDownLeft className="size-4"/>
                        </div> :
                        <div className="flex items-center justify-center size-6 text-green-500 rounded-full shrink-0">
                            <MoveUpRight className="size-4"/>
                        </div>
                ),
            },
            {
                header: "Descrição",
                accessorKey: "description",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Valor",
                accessorKey: "amount",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => InputMasker.maskMoney(cell.getValue()),
            },
            {
                header: "Data",
                accessorKey: "date",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => InputMasker.formatDateToUser(cell.getValue()),
            },
            {
                header: "Status",
                accessorKey: "is_completed",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => <MovementStatus is_completed={cell.getValue()} type={cell?.row.original.type}/>,
            },
            {
                header: "Ação",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => (
                    <div className="flex gap-2">
                        <Link
                            to="#"
                            className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 dark:bg-zink-600 dark:text-zink-200 text-slate-500 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-100 dark:hover:bg-custom-500/20"
                            onClick={() => handleUpdateDataClick(cell.row.original)}
                        >
                            <Pencil className="size-4"/>
                        </Link>
                        <Link
                            to="#"
                            className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 dark:bg-zink-600 dark:text-zink-200 text-slate-500 hover:text-red-500 dark:hover:text-red-500 hover:bg-red-100 dark:hover:bg-red-500/20"
                            onClick={() => {
                                setDeleteModal(true);
                                setEventData(cell.row.original);
                            }}
                        >
                            <Trash2 className="size-4"/>
                        </Link>
                    </div>
                ),
            }
        ], []
    );

    return (
        <React.Fragment>
            <DeleteModal show={deleteModal} onHide={() => setDeleteModal(false)} onDelete={handleDelete}/>
            <div className="card">
                <div className="card-body">
                    <div className="grid items-center mb-5">
                        <div className='flex justify-between items-center'>
                            <div className="w-[40%]">
                                <h6 className="text-15 ml-5">Listagem de entrada/saidas</h6>
                            </div>
                            <div className="flex justify-around w-[50%] gap-3 items-end">
                                <div className="relative grow">
                                    <input
                                        type="text"
                                        className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        placeholder="Busque pela descrição" autoComplete="off"
                                        onChange={(e) => filterSearchData(e)}/>
                                    <Search
                                        className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600"/>
                                </div>
                                <button
                                    type="button"
                                    className="bg-white  shrink-0 text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                                    onClick={toggle}
                                >
                                    Adicionar
                                </button>
                                <button
                                    type="button"
                                    onClick={() => handleDownloadCsv()}
                                    className="px-2 py-1.5 text-base text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 rounded-md  font-bold h-[40px] sm:w-full sm:mt-5 xl:w-[280px] 2xl:w-[280px] md:w-[280px] flex items-center justify-around"
                                >
                                    Exportar Relatorio
                                    <FileDown height={'25px'}/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <TableContainer
                        isPagination={true}
                        columns={(columns || [])}
                        data={(data || [])}
                        customPageSize={10}
                        divclassName="mx-5 overflow-x-auto h-[350px]"
                        tableclassName="w-full whitespace-nowrap"
                        theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
                        thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-y border-slate-200 dark:border-zink-500 w-10"
                        tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
                        PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                    />
                </div>
                <Modal
                    show={show}
                    onHide={toggle}
                    id="defaultModal"
                    modal-center="true"
                    className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
                    dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
                >
                    <Modal.Header
                        className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                        closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
                    >
                        <Modal.Title
                            className="text-16">
                            {!!isEdit ? "Editar Movimentação" : "Criar nova Movimentação"}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
                        <form action="#!" onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                            <div className="mb-3">
                                <label htmlFor="descriptionInput"
                                       className="inline-block mb-2 text-base font-medium">Descrição</label>
                                <input
                                    type="text"
                                    id="descriptionInput"
                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    placeholder="Digite uma descrição"
                                    name="description"
                                    onChange={validation.handleChange}
                                    value={validation.values.description || ""}
                                />
                                <ValidationError validation={validation} fieldName="description"/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="amountInput"
                                       className="inline-block mb-2 text-base font-medium">Valor</label>
                                <input type="text"
                                       id="amountInput"
                                       className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                       placeholder="R$ 1000,00"
                                       name="amount"
                                       onChange={validation.handleChange}
                                       value={InputMasker.maskMoney(validation.values.amount) || ""}
                                />
                                <ValidationError validation={validation} fieldName="amount"/>
                            </div>
                            <div className="mb-3">
                                <h6 className="mb-1 text-15">Data da Movimentação</h6>
                                <Flatpickr
                                    options={{
                                        dateFormat: "d/m/Y",
                                        locale: "pt",
                                    }}
                                    placeholder="Select Date"
                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    name='start_date'
                                    value={startDate}
                                    onChange={(value: Date[]) => setStartDate(value[0])}
                                />
                                <ValidationError validation={validation} fieldName="start_date"/>
                            </div>
                            <div className={validation.values.type === 'cash_out' ? 'flex justify-between' : ''}>
                                <div className="mb-3">
                                    <label
                                        htmlFor="movement_type"
                                        className="inline-block mb-2 text-base font-medium"
                                    >
                                        Tipo de movimentação
                                    </label>
                                    <select
                                        className={`form-input focus:outline-none focus:border-custom-500 font-bold ${validation.values.type == 'cash_out' ? 'border-red-600 bg-red-100 text-red-600' : 'border-green-600 bg-green-100 text-green-600'}`}
                                        data-choices
                                        data-choices-search-false
                                        id="movement_type"
                                        name="type"
                                        onChange={validation.handleChange}
                                        value={validation.values.type || "cash_in"}
                                    >
                                        <option value="cash_in">Entrada</option>
                                        <option value="cash_out">Saida</option>
                                    </select>
                                    <ValidationError validation={validation} fieldName="type"/>
                                </div>
                                {
                                    validation.values.type === 'cash_out' && (
                                        <SelectInputWithClassNameLogic
                                            validation={validation}
                                            fieldName="cashout_type"
                                            label='Tipo de despesa'
                                            options={[
                                                {value: '', description: 'Escolha um tipo de despesa'},
                                                {value: 'despesa_fixa', description: 'Despesa Fixa'},
                                                {value: 'despesa_variavel', description: 'Despesa Variavel'},
                                            ]}
                                            classNameLogics={[
                                                {
                                                    logic: validation.values.cashout_type == 'despesa_variavel',
                                                    className: 'border-pink-600 bg-pink-100 text-pink-600'
                                                },
                                                {
                                                    logic: validation.values.cashout_type == 'despesa_fixa',
                                                    className: 'border-purple-600 bg-purple-100 text-purple-600'
                                                },
                                            ]}
                                        />
                                    )
                                }
                            </div>


                            <div className="mb-3">
                                <label
                                    htmlFor="is_completed"
                                    className="inline-block mb-2 text-base font-medium"
                                >
                                    Status da Movimentação
                                </label>
                                <select
                                    className={`form-input focus:outline-none focus:border-custom-500 font-bold ${validation.values.is_completed == 'true' ? 'border-green-600 bg-green-100 text-green-600' : 'border-yellow-600 bg-yellow-100 border-transparent text-yellow-600'}`}
                                    data-choices
                                    data-choices-search-false
                                    id="is_completed"
                                    name="is_completed"
                                    onChange={validation.handleChange}
                                    value={validation.values.is_completed || ""}
                                >
                                    <option value="false">
                                        <MovementStatus
                                            is_completed={false}
                                            type={validation.values.type ?? ''}
                                        />
                                    </option>
                                    <option value="true">
                                        <MovementStatus
                                            is_completed={true}
                                            type={validation.values.type ?? ''}
                                        />
                                    </option>

                                </select>
                                {validation.touched.role && validation.errors.role ? (
                                    <p className="text-red-400">{validation.errors.role}</p>
                                ) : null}
                            </div>
                            <div className={`${isUniqueMovement ? '' : 'flex justify-between items-end'}  w-full`}>
                                {
                                    !isUniqueMovement && (
                                        <div>
                                            <label
                                                htmlFor="is_completed"
                                                className="inline-block mb-2 text-base font-medium"
                                            >
                                                Quantidade de repetições
                                            </label>
                                            <select
                                                className={`form-input focus:outline-none focus:border-custom-500 font-bold border-blue-600 bg-blue-100 text-blue-600`}
                                                data-choices
                                                data-choices-search-false
                                                id="qty_repeat"
                                                name="qty_repeat"
                                                onChange={validation.handleChange}
                                                value={validation.values.qty_repeat || 1}
                                            >
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </div>
                                    )
                                }
                                <div className="flex items-center" style={{float: 'right', clear: 'both'}}>
                                    <label
                                        htmlFor="customDefaultSwitch"
                                        className="inline-block text-base font-medium cursor-pointer"
                                    >
                                        {validation.values.type == 'cash_out' ? 'Despesa unica ' : 'Receita unica '}
                                    </label>
                                    &nbsp;
                                    <div
                                        className="relative inline-block w-10 align-middle transition duration-200 ease-in ltr:mr-2 rtl:ml-2">
                                        <input
                                            type="checkbox"
                                            name="customDefaultSwitch"
                                            id="customDefaultSwitch"
                                            className="absolute block size-5 transition duration-300 ease-linear border-2 rounded-full appearance-none cursor-pointer border-slate-200 dark:border-zink-500 bg-white/80 dark:bg-zink-400 peer/published checked:bg-white dark:checked:bg-white ltr:checked:right-0 rtl:checked:left-0 checked:bg-none checked:border-custom-500 dark:checked:border-custom-500 arrow-none"
                                            checked={isUniqueMovement}
                                            onChange={() => setIsUniqueMovement(!isUniqueMovement)}
                                        />
                                        <label
                                            htmlFor="customDefaultSwitch"
                                            className="block h-5 overflow-hidden duration-300 ease-linear border rounded-full cursor-pointer cursor-pointertransition border-slate-200 dark:border-zink-500 bg-slate-200 dark:bg-zink-600 peer-checked/published:bg-custom-500 peer-checked/published:border-custom-500"
                                        ></label>
                                    </div>
                                </div>
                            </div>


                            <br/>

                            <div className="flex justify-end gap-2 mt-4">
                                <button
                                    type="reset"
                                    data-modal-close="addDocuments"
                                    className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                                    onClick={toggle}
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="submit"
                                    className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                                    onClick={validation.handleSubmit}
                                >
                                    {!!isEdit ? "Atualizar Movimentação" : "Adicionar Movimentação"}
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    );
};

export default FinancialMovementList;
