export const PERMISSIONS = {
    CREATE_USER: 'create_user',
    LIST_USERS: 'list_users',
    FILTER_USERS: 'filter_users',
    CREATE_ADMIN: 'create_admin',
    VIEW_PROFILE: 'view_profile',
    CREATE_PRODUCT: 'create_product',
    LIST_PRODUCTS: 'list_products',
    CREATE_SALES: 'create_sales',
    CREATE_FINANCIAL_MOVEMENT: 'create_financial_movement',
    VIEW_DASHBORAD:'view_dashboard',
    UPDATE_PRODUCT_IMAGE:'update_product_images',
}

export type UserPermission = {
    id: number;
    name: string;
}

export type UserType = {
    id: number;
    email: string;
    name: string;
    role: string;
    token: string;
    permissions: UserPermission[];
}

export class User {
    id: number;
    email: string;
    name: string;
    role: string;
    token: string;
    permissions: UserPermission[];

    constructor(
        id: number,
        email: string,
        name: string,
        role: string,
        token: string, permissions: UserPermission[]
    ) {
        this.id = id;
        this.email = email;
        this.name = name;
        this.role = role;
        this.token = token;
        this.permissions = permissions;
    }

    public translateRole() {
        switch (this.role) {
            case 'super_admin':
                return 'Super Admin';
            case 'admin':
                return 'Administrador';
            case 'gerente':
                return 'Gerente';
            case 'vendedor':
                return 'Vendedor';
            case 'cliente':
                return 'Cliente';
            default:
                return 'Nivel de acesso não identificado';
        }
    }

    public getRedirectPage() {
        switch (this.role) {
            case 'super_admin':
                return '/products/list';
            case 'admin':
                return '/products/list';
            case 'gerente':
                return '/products/list';
            case 'vendedor':
                return '/products/list';
            case 'marketing':
                return '/products/list';
            case 'cliente':
                return '404';
            default:
                return '/products/list';
        }
    }

    public hasPermission(permissionName: string): boolean {
        return this.permissions.some(permission => permission.name === permissionName);
    }

    public hasAnyPermission(permissionNames: string[]): boolean {
        return this.permissions.some(permission => permissionNames.includes(permission.name));
    }

    public canCreateProduct() {
        return this.hasPermission(PERMISSIONS.CREATE_PRODUCT)
    }
}

export default class AuthUser {
    private static user: User | null = null;

    static get(): User | null {
        if(this.user) {
            return this.user;
        }
        const user = localStorage.getItem("authUser");
        if(!user) {
            return null;
        }
        const userJson: UserType = JSON.parse(user);
        return new User(userJson.id, userJson.email, userJson.name, userJson.role, userJson.token, userJson.permissions);
    }

    static set(userJson: User) {
        this.user = new User(userJson.id, userJson.email, userJson.name, userJson.role, userJson.token, userJson.permissions);
        localStorage.setItem("authUser", JSON.stringify(userJson));
    }

    static remove() {
        this.user = null;
        localStorage.removeItem("authUser");
    }
}