import React, {useCallback, useMemo, useState} from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import {Link} from 'react-router-dom';
import {Dropdown} from 'Common/Components/Dropdown';
import TableContainer from 'Common/TableContainer';

// Icons
import {FileEdit, MoreHorizontal, Plus, Search, Trash2} from 'lucide-react';
import Modal from 'Common/Components/Modal';
import DeleteModal from 'Common/DeleteModal';

import * as Yup from "yup";
import {useFormik} from "formik";
import {toast, ToastContainer} from 'react-toastify';
import getUserList from "../../Backend/Api/User/get-user-list";
import getRoles, {Role} from "../../Backend/Api/Auth/get-roles";
import useHandleAxiosRequest from "../../Hooks/useHandleAxiosRequest";
import createUser from "../../Backend/Api/User/create-user";
import ErrorHelper from "../../Backend/Services/Helpers/ErrorHelper";
import updateUser, {UpdateUserPayload} from "../../Backend/Api/User/update-user";
import deleteUser from "../../Backend/Api/User/delete-user";
import {User} from "../../Backend/Services/Auth/AuthUser";

const UserList = () => {
    const [roles] = useHandleAxiosRequest(async () => getRoles(), [])
    const [userList] = useHandleAxiosRequest(async () => getUserList(), [])
    const [user, setUser] = useHandleAxiosRequest(async () => getUserList(), [])
    const [, , handleRequest] = useHandleAxiosRequest();
    const [eventData, setEventData] = useState<any>();

    const [show, setShow] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const deleteToggle = () => setDeleteModal(!deleteModal);

    const onClickDelete = (cell: any) => {
        setDeleteModal(true);
        if (cell.id) {
            setEventData(cell);
        }
    };

    function reloadUser() {
        handleRequest(() => getUserList())
            .then((response: any) => {
                setUser(response);
                userList(response);
            });
    }

    const handleDelete = () => {
        if (eventData) {
            handleRequest(() => deleteUser(eventData.token))
                .then(() => {
                    toast("Usuario deletado!", {autoClose: 2000, type: "success"})
                    setDeleteModal(false);
                    reloadUser();
                });

        }
    };

    const handleUpdateDataClick = (ele: any) => {
        setEventData({...ele});
        setIsEdit(true);
        setShow(true);
    };

    const validation: any = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: eventData?.name ?? '',
            email: eventData?.email ?? '',
            role: eventData?.role ?? '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Por favor digite um nome"),
            email: Yup.string().required("Por favor digite um email"),
            role: Yup.string().required("Por favor escolha uma nivel de acesso")
        }),

        onSubmit: (values) => {
            try {
                if (isEdit) {
                    const updateUserData = {
                        token: eventData ? eventData.token : '',
                        ...values,
                    } as UpdateUserPayload;
                    handleRequest(() => updateUser(updateUserData)).then(() => {
                        toast("Usuario atualizado!", {autoClose: 2000, type: "success"})
                        toggle();
                        reloadUser();
                    });
                } else {
                    handleRequest(() => createUser(values)).then(() => {
                        toast("Usuario cadastrado com sucesso", {autoClose: 2000, type: "success"})
                        toggle();
                        reloadUser();
                    });
                }
            } catch (e) {
                ErrorHelper.handleError(e);
            }
        },
    });

    const toggle = useCallback(() => {
        if (show) {
            setShow(false);
            setEventData("");
            setIsEdit(false);
        } else {
            setShow(true);
            setEventData("");
            validation.resetForm();
        }
    }, [show, validation]);

    const filterSearchData = (e: any) => {
        const search = e.target.value;
        if (search == '') {
            setUser(userList);
            return;
        }

        const filteredUser = userList.filter((userFiltered: User) => {
            return userFiltered.id == search
                || userFiltered.token === search
                || userFiltered.name.toLowerCase().includes(search)
                || userFiltered.email.toLowerCase().includes(search);
        });
        setUser(filteredUser);
    };

    const NivelAcesso = ({item}: any) => {
        switch (item) {
            case "super_admin":
                return (<span
                    className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status">{item}</span>);
            case "admin":
                return (<span
                    className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status">
                    {item}</span>);
            case "gerente":
                return (<span
                    className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status">
                    {item}</span>);
            case "vendedor":
                return (<span
                    className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status">
                    {item}</span>);
            case "cliente":
                return (<span
                    className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status">
                    {item}</span>);

            default:
                return (<span
                    className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status">
                    {item}</span>);
        }
    };

    const columns = useMemo(() => [
            {
                header: (
                    <div className="flex items-center h-full">
                        <input id="CheckboxAll"
                               className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
                               type="checkbox"/>
                    </div>
                ),
                enableSorting: false,
                id: "checkAll",
                cell: (cell: any) => {
                    return (
                        <div className="flex items-center h-full">
                            <input id="Checkbox1"
                                   className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
                                   type="checkbox"/>
                        </div>
                    );
                }
            },
            {
                header: "User ID",
                accessorKey: "id",
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <Link
                        to="#!"
                        className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"
                    >
                        {cell.getValue()}
                    </Link>
                ),
            },
            {
                header: "Nome",
                accessorKey: "name",
                enableColumnFilter: false,
            },
            {
                header: "Email",
                accessorKey: "email",
                enableColumnFilter: false,
            },
            {
                header: "Nivel de acesso",
                accessorKey: "role",
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <NivelAcesso item={cell.getValue()}/>
                )
            },
            {
                header: "Action",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => (
                    <Dropdown className="relative">
                        <Dropdown.Trigger
                            className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
                            id="usersAction1">
                            <MoreHorizontal className="size-3"/>
                        </Dropdown.Trigger>
                        <Dropdown.Content
                            placement="right-end"
                            className="absolute z-100 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600"
                            aria-labelledby="usersAction1"
                        >
                            <li>
                                <Link data-modal-target="addUserModal"
                                      className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                                      to="#!"
                                      onClick={() => {
                                          const data = cell.row.original;
                                          handleUpdateDataClick(data);
                                      }}>
                                    <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1"/> <span
                                    className="align-middle">Edit</span></Link>
                            </li>
                            <li>
                                <Link
                                    className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                                    to="#!" onClick={() => {
                                    const orderData = cell.row.original;
                                    onClickDelete(orderData);
                                }}>
                                    <Trash2 className="inline-block size-3 ltr:mr-1 rtl:ml-1"/> <span
                                    className="align-middle">Delete</span></Link>
                            </li>
                        </Dropdown.Content>
                        <br/>
                    </Dropdown>
                ),
            }
        ], []
    );

    return (
        <React.Fragment>
            <BreadCrumb title='Usuarios' pageTitle='Users'/>
            <DeleteModal show={deleteModal} onHide={deleteToggle} onDelete={handleDelete}/>
            <ToastContainer closeButton={false} limit={1}/>
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
                <div className="xl:col-span-12">
                    <div className="card" id="usersTable">
                        <div className="card-body">
                            <div className="flex items-center">
                                <h6 className="text-15 grow">Lista de usuarios</h6>
                                <div className="shrink-0">
                                    <button type="button"
                                            className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                                            onClick={toggle}><Plus className="inline-block size-4"/> <span
                                        className="align-middle">Adicionar Usuario</span></button>
                                </div>
                            </div>
                        </div>
                        <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
                            <form action="#!">
                                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                                    <div className="relative xl:col-span-2">
                                        <input type="text"
                                               className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                               placeholder="Busque por nome, email ou token"
                                               autoComplete="off" onChange={(e) => filterSearchData(e)}/>
                                        <Search
                                            className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600"/>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="card-body">
                            {user && user.length > 0 ?
                                <TableContainer
                                    isPagination={true}
                                    columns={(columns || [])}
                                    data={(user || [])}
                                    customPageSize={10}
                                    divclassName="-mx-5 -mb-5 overflow-x-auto"
                                    tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                                    theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                                    thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                                    tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                                    PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                                />
                                :
                                (<div className="noresult">
                                    <div className="py-6 text-center">
                                        <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20"/>
                                        <h5 className="mt-2 mb-1">Nenhum usuario encontrado!</h5>
                                    </div>
                                </div>)}
                        </div>
                    </div>
                </div>
            </div>


            <Modal
                show={show}
                onHide={toggle}
                id="defaultModal"
                modal-center="true"
                className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
                dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
            >
                <Modal.Header
                    className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
                >
                    <Modal.Title className="text-16">{!!isEdit ? "Edit User" : "Add User"}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
                    <form action="#!" onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        <div className="mb-3">
                            <label htmlFor="userNameInput"
                                   className="inline-block mb-2 text-base font-medium">Nome</label>
                            <input
                                type="text"
                                id="userNameInput"
                                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                placeholder="Digite um nome"
                                name="name"
                                onChange={validation.handleChange}
                                value={validation.values.name || ""}
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <p className="text-red-400">{validation.errors.name}</p>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="emailInput"
                                   className="inline-block mb-2 text-base font-medium">Email</label>
                            <input type="email" id="emailInput"
                                   className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                   placeholder="Digite um email"
                                   name="email"
                                   onChange={validation.handleChange}
                                   value={validation.values.email || ""}
                            />
                            {validation.touched.email && validation.errors.email ? (
                                <p className="text-red-400">{validation.errors.email}</p>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="roleSelect"
                                   className="inline-block mb-2 text-base font-medium">Nivel de acesso</label>
                            <select
                                className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                                data-choices
                                data-choices-search-false
                                id="role"
                                name="role"
                                onChange={validation.handleChange}
                                value={validation.values.role || ""}
                            >
                                <option value="">Selecione um nivel de acesso</option>
                                {roles.map((role: Role) => (
                                    <option key={role.id} value={role.name}>{role.display_name}</option>
                                ))}
                            </select>
                            {validation.touched.role && validation.errors.role ? (
                                <p className="text-red-400">{validation.errors.role}</p>
                            ) : null}
                        </div>

                        <div className="flex justify-end gap-2 mt-4">
                            <button
                                type="reset"
                                data-modal-close="addDocuments"
                                className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                                onClick={toggle}
                            >
                                Cancelar
                            </button>
                            <button
                                type="submit"
                                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                            >
                                {!!isEdit ? "Atualizar Usuario" : "Adicionar Usuario"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default UserList;
