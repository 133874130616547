import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";

import sendPasswordResetEmail from "../../Backend/Api/Auth/send-password-reset-email";
import useHandleAxiosRequest from "../../Hooks/useHandleAxiosRequest";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import validatePasswordResetToken from "../../Backend/Api/Auth/validate-password-reset-token";
import resetPassword from "../../Backend/Api/Auth/reset-password";

const ResetPassword = () => {

    document.title = "Westside | Recuperar senha";

    const [,, handleRequest] = useHandleAxiosRequest();
    const navigate = useNavigate();
    const [codeSent, setCodeSent] = useState(false);
    const [codeConfirmed, setCodeConfirmed] = useState(false);
    const [code, setCode] = useState('');
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    function sendCode() {
        if(!email) {
            return;
        }
        handleRequest(() => sendPasswordResetEmail(email))
            .then(() => {
                toast.success("Email enviado com sucesso, confira sua caixa de entrada!");
                setCodeSent(true);
            })
            .catch(() => {
                setCodeSent(false);
            });
    }

    function validateCode() {
        if(!code) {
            return;
        }
        handleRequest(() => validatePasswordResetToken(code))
            .then(() => {
                toast.success("Codigo validado, agora digite sua nova senha");
                setCodeConfirmed(true);
            })
            .catch(() => {
                setCodeConfirmed(false);
            });
    }

    function handleResetPassword() {
        if(!code || !password) {
            return;
        }
        handleRequest(() => resetPassword(code, password))
            .then(() => {
                toast.success("Senha alterada com sucesso");
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            })
            .catch(() => {
                setCodeConfirmed(false);
            });
    }

    React.useEffect(() => {
        const bodyElement = document.body;

        bodyElement.classList.add('flex', 'items-center', 'justify-center', 'min-h-screen', 'py-16', 'lg:py-10', 'bg-slate-50', 'dark:bg-zink-800', 'dark:text-zink-100', 'font-public');

        return () => {
            bodyElement.classList.remove('flex', 'items-center', 'justify-center', 'min-h-screen', 'py-16', 'lg:py-10', 'bg-slate-50', 'dark:bg-zink-800', 'dark:text-zink-100', 'font-public');
        }
    }, []);

    return (
        <React.Fragment>
            <ToastContainer/>
            <div className="relative">
                <div className="mb-0 w-screen lg:w-[500px] card shadow-lg border-none shadow-slate-100 relative">
                    <div className="!px-10 !py-12 card-body">
                        <Link to="/">
                            <img src='/images/logo.jpeg' alt="" className="mx-auto"
                                 style={{width: '200px', borderRadius: '50px'}}/>
                        </Link>

                        {!codeSent && (
                            <div className="mt-8 text-center">
                                <h4 className="mb-1 text-custom-500 dark:text-custom-500">Recuperar senha</h4>
                                <p className="text-slate-500 dark:text-zink-200">Nós enviaremos um codigo para seu email</p>
                            </div>
                        )}

                        {codeSent && !codeConfirmed && (
                            <div className="mt-8 text-center">
                                <h4 className="mb-1 text-custom-500 dark:text-custom-500">Validar codigo</h4>
                                <p className="text-slate-500 dark:text-zink-200">Digite a baixo o codigo recebido por email</p>
                            </div>
                        )}

                        {codeSent && codeConfirmed && (
                            <div className="mt-8 text-center">
                                <h4 className="mb-1 text-custom-500 dark:text-custom-500">Alterar senha</h4>
                                <p className="text-slate-500 dark:text-zink-200">Digite sua nova senha</p>
                            </div>
                        )}

                        <form action="/" className="mt-10" id="registerForm"
                              onSubmit={(event: any) => {
                                  event.preventDefault();
                              }}>
                            {
                                !codeSent && (
                                    <div className="mb-3">
                                        <label htmlFor="email-field"
                                               className="inline-block mb-2 text-base font-medium">Email</label>
                                        <input
                                            type="text"
                                            id="email-field"
                                            name="email"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Enter email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}/>
                                    </div>
                                )
                            }

                            {codeSent && !codeConfirmed && <>
                                <div className="mb-3">
                                    <label htmlFor="code-field"
                                           className="inline-block mb-2 text-base font-medium">Codigo</label>
                                    <input
                                        type="text"
                                        id="code-field"
                                        name="code"
                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        placeholder="Digite o codigo recebido por email"
                                        onChange={(e) => setCode(e.target.value)}
                                        value={code}
                                    />
                                </div>
                                <div className="mt-10">
                                    <button
                                        type="button"
                                        className="w-full text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                                        onClick={() => validateCode()}
                                    >
                                        Validar codigo
                                    </button>
                                </div>
                            </>
                            }
                            {codeSent
                                && codeConfirmed
                                && (
                                    <>
                                        <div className="mb-3">
                                            <label htmlFor="password-field"
                                                   className="inline-block mb-2 text-base font-medium">Senha</label>
                                            <input
                                                type="password"
                                                id="password-field"
                                                name="password"
                                                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                placeholder="Digite sua nova senha"
                                                onChange={(e) => setPassword(e.target.value)}
                                                value={password}
                                            />
                                        </div>
                                        <div className="mt-10">
                                            <button
                                                type="submit"
                                                className="w-full text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                                                onClick={() => handleResetPassword()}
                                            >
                                                Alterar senha
                                            </button>
                                        </div>
                                    </>
                                )}
                            {!codeSent &&
                                <div className="mt-10">
                                    <button
                                        type="button"
                                        className="w-full text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                                        onClick={() => sendCode()}
                                    >
                                        Receber codigo por email
                                    </button>
                                </div>
                            }

                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ResetPassword;