import {toast, ToastContainer} from 'react-toastify';
import React, {useCallback, useEffect, useState} from "react";
import {Search} from 'lucide-react';
import BreadCrumb from "Common/BreadCrumb";
import {Link} from "react-router-dom";
import * as Yup from "yup";
import Modal from 'Common/Components/Modal';
import createSales from 'Backend/Api/Sales/create-sales';
import useHandleAxiosRequest from 'Hooks/useHandleAxiosRequest';
import InputMasker from 'Backend/Services/Helpers/InputMasker';
import InputWithLabel from 'pages/Components/Custom/InputWithLabel';
import {useFormik} from 'formik';
import ErrorHelper from "../../Backend/Services/Helpers/ErrorHelper";
import getProductsForSales from "../../Backend/Api/Product/get-products-for-sales";
import {Product} from "../../Backend/Api/Product/get-products";
import AmountInputWithLabel from "../Components/Custom/AmountInputWithLabel";
import LoadingModal from "../Components/Custom/LoadingModal";

const Sales = () => {
    const [filter, setFilter] = useState<any>('');
    const [, , handleRequest] = useHandleAxiosRequest()
    const [productList, , handleProductListRequest] = useHandleAxiosRequest(() => getProductsForSales(), []);
    const [productToSell, setProductToSell] = useState<Product | null>(null)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (filter.trim().length == '') {
            setLoading(true)
            handleProductListRequest(() => getProductsForSales())
                .then(() => setLoading(false))
                .catch(() => setLoading(false))
            return;
        }

        const handler = setTimeout(() => {
            let search = '?search=' + filter
            setLoading(true)
            handleProductListRequest(() => getProductsForSales(search))
                .then(() => setLoading(false))
                .catch(() => setLoading(false))

        }, 1000);

        return () => {
            clearTimeout(handler);
        };
    }, [filter]);

    const cancelSales = useCallback(() => {
        setProductToSell(null)
        toast("Venda cancelada!", {autoClose: 1000, type: "success"});
    }, [productToSell]);

    const validation: any = useFormik({
        enableReinitialize: true,

        initialValues: {
            customer_phone: '',
            customer_name: '',
            amount: '',
        },
        validationSchema: Yup.object({
            customer_name: Yup.string().required("Por favor digite o Nome do Cliente"),
            customer_phone: Yup.string().required("Por favor digite o Telefone do Cliente"),
            amount: Yup.string().required("Por favor digite o valor"),
        }),

        onSubmit: (values: any) => {
            try {
                insertSales(values)
            } catch (e) {
                ErrorHelper.handleError(e);
            }
        },
    });

    const insertSales = useCallback((values: any) => {
        values = {
            ...values,
            product_id: productToSell?.id,
        }
        handleRequest(() => createSales(values)).then(() => {
            toast("Venda concluída com sucesso!", {autoClose: 1000, type: "success"});
            setLoading(true)
            handleProductListRequest(() => getProductsForSales())
                .then(() => setLoading(false))
                .catch(() => setLoading(false))
            setProductToSell(null)
        })
    }, [productToSell]);

    return (
        <React.Fragment>
            <BreadCrumb title='Cadastrar vendas' pageTitle='Vendas'/>
            <ToastContainer closeButton={false} limit={1}/>
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
                <div className="xl:col-span-12">
                    <div className="card " id="usersTable">
                        <div className="card-body">
                            <div className="flex items-center">
                                <div className="relative w-full">
                                    <input
                                        type="text"
                                        className="w-full ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        placeholder="Busque por placa, marca, modelo"
                                        autoComplete="off" onChange={(e) => setFilter(e.target.value)}
                                    />
                                    <Search
                                        className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600"
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500 bg-slate-100 relative">
                            {loading && <LoadingModal />}
                            <div className={'grid grid-cols-1 gap-x-5 w-[100%] overflow-y-scroll max-h-[80vh]'}>
                                <div className="xl:col-span-9 products-list w-[100%] ">

                                    {productList?.map((item: any, key: number) => (
                                        <div className="card products" id={`product${item.id}`} key={key}>
                                            <div className="card-body">
                                                <div className="grid grid-cols-1 gap-4 lg:grid-cols-12">
                                                    <div className="flex flex-col lg:col-span-4">
                                                        <div>
                                                            <h5 className="mb-1 text-16"><Link
                                                                to="/apps-ecommerce-product-overview">Placa: {item?.placa}</Link>
                                                            </h5>
                                                            <p className="mb-3 text-slate-500 dark:text-zink-200">Marca: <span
                                                                className="text-slate-800 dark:text-zink-50">{item?.marca}</span>
                                                            </p>
                                                            <p className="mb-3 text-slate-500 dark:text-zink-200">Modelo: <span
                                                                className="text-slate-800 dark:text-zink-50">{item?.modelo}</span>
                                                            </p>
                                                            <p className="mb-1 text-slate-500 dark:text-zink-200">Cor: <span
                                                                className="text-slate-800 dark:text-zink-50">{item?.cor}</span>
                                                            </p>
                                                            {item?.acessorios &&
                                                                <p className="mb-1 text-slate-500 dark:text-zink-200">Acessorios: <span
                                                                    className="text-slate-800 dark:text-zink-50">{item?.acessorios}</span>
                                                                </p>}
                                                        </div>

                                                    </div>
                                                    <div
                                                        className="flex justify-between w-full lg:flex-col lg:col-end-13 lg:col-span-2">
                                                        <div className="mb-auto ltr:lg:text-right rtl:lg:text-left">
                                                            <button
                                                                type="button"
                                                                onClick={() => setProductToSell(item)}
                                                                className="px-2 py-1.5 text-base text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 font-bold"
                                                            >
                                                                Selecionar
                                                            </button>
                                                        </div>
                                                        <h6 className="mt-auto text-16 ltr:lg:text-right rtl:lg:text-left">R$<span
                                                            className="products-line-price">{item?.valor?.amount_string}</span>
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                    {!productList?.length && <div className="noresult">
                                        <div className="py-6 text-center">
                                            <Search
                                                className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20"/>
                                            <h5 className="mt-2 mb-1">Busque um produto no filtro</h5>
                                            <p className="mb-0 text-medium dark:text-zink-200">Digite ao menos 3 letras
                                                para iniciar a busca</p>
                                            <p className="mb-0 text-slate-500 dark:text-zink-200">Busque pela placa,
                                                marca ou modelo do veiculo</p>
                                        </div>
                                    </div>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={productToSell !== null} onHide={() => setProductToSell(null)}
                id="defaultModal"
                modal-center="true"
                className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
                dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
                <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                              closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
                    <Modal.Title className="text-16">Tem certeza que deseja concluir essa venda?</Modal.Title>
                </Modal.Header>
                <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
                    <div>
                        <div className="card-body">
                            <div className="overflow-x-auto">
                                <div className="w-full">
                                    <div className="flex items-center gap-5 p-4 mt-5 card w-full">
                                        <div
                                            className="flex items-center justify-center size-16 rounded-md bg-slate-100 dark:bg-zink-600">
                                            <img className='h-full w-full' src={productToSell?.images[0]?.url} alt=""/>
                                        </div>
                                        <div className='w-full'>
                                            <div className='flex justify-between w-full'>
                                                <div>
                                                    <h6 className="mb-1">{productToSell?.marca} / {productToSell?.modelo} </h6>
                                                </div>
                                            </div>
                                            <p className="text-slate-500">R$ {productToSell?.valor?.amount_string}</p>
                                        </div>
                                    </div>

                                    <InputWithLabel
                                        validation={validation}
                                        name="customer_name"
                                        label='Nome do Cliente'
                                        id="clientNameInput"
                                        placeholder="Digite o nome do cliente"
                                    />
                                    <InputWithLabel
                                        validation={validation}
                                        name="customer_phone"
                                        label='Telefone do Cliente'
                                        id="clientPhoneInput"
                                        placeholder="Digite o telefone do cliente"
                                        mask={InputMasker.maskPhone}
                                    />
                                    <AmountInputWithLabel
                                        validation={validation}
                                        name="amount"
                                        label='Valor'
                                        id="amountInput"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-2 mt-5 shrink-0">
                            <button
                                onClick={cancelSales}
                                className="w-full text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-red-400/20"
                            >
                                Não, cancelar
                            </button>
                            <button
                                onClick={validation.handleSubmit}
                                className={`w-full text-white btn 'bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 bg-custom-500 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600  active:ring active:ring-custom-100 dark:ring-custom-400/20`}
                            >
                                Sim, Tenho certeza
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default Sales;
