import getApi from "../axios/api";
import {AxiosResponse} from "axios";
import { UploadMediaResponse } from "../../../Backend/Api/Media/upload-media";
export type Amount = {
    amount_cents: number;
    amount_string: string;
}
export type ProductType = {
    id: number;
    name: string;
    description: string;
}

export type ProductStatus = {
    id: number;
    name: string;
}

export type Product = {
    id: number;
    marca: string;
    modelo: string;
    token: string;
    ano: number;
    placa: string;
    cor: string;
    km: number;
    fabricacao: number;
    valor: Amount;
    valor_fipe: Amount;
    commission: number;
    diferenca: Amount;
    product_type: ProductType;
    created_by: number;
    ipva_pago: boolean;
    ticket_to_auction: boolean;
    manually: boolean;
    spare_key: boolean;
    acessorios?: string;
    status?: ProductStatus;
}


export default async function getProductsById(id: number): Promise<Product[]> {
    const response: AxiosResponse<Product[]> = await getApi().get("/api/product/"+id);
    return response.data;
}