import React from "react";

export type InputTextAreaWithLabelProps = {
    validation: any;
    name: string;
    label: string;
    mask?: (value: string) => string;
    id: string;
    placeholder: string;
    defaultRows?: number;
    disabled?: boolean;
}

export default function TextAreaWithLabel(
    {
        validation,
        name,
        label,
        id,
        placeholder,
        defaultRows,
        disabled,
    }: InputTextAreaWithLabelProps
) {
    return (
        <div className="mb-3">
            <label
                htmlFor={id}
                className="inline-block mb-2 text-base font-medium"
            >
                {label}
            </label>
            <textarea
                disabled={disabled}
                id={id}
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder={placeholder}
                name={name}
                onChange={validation.handleChange}
                value={validation.values[name] ?? ''}
                rows={defaultRows}
            />
            {(validation.touched.hasOwnProperty(name) ?? null) && (validation.errors.hasOwnProperty(name) ?? null) ? (
                <p className="text-red-400">{validation.errors[name]}</p>
            ) : null}
        </div>
    )
}