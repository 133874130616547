import getApi from "../axios/api";
import {AxiosResponse} from "axios";
import {Product} from "./get-products";
import {CreateProductInput} from "./create-product";
import InputMasker from "../../Services/Helpers/InputMasker";
import {UploadMediaResponse} from "../Media/upload-media";

export default async function updateProduct(product: CreateProductInput, uploadImages: UploadMediaResponse[]): Promise<Product> {
    if(!product.anexos) {
        product.anexos = [];
    }
    product.anexos.push({
        id_anexo: parseInt(product.document),
        type: "document"
    });
    if(product.laudo_cautelar && product.laudo_cautelar !== "") {
        product.anexos.push({
            id_anexo: parseInt(product.laudo_cautelar),
            type: "laudo_cautelar"
        });
    }
    if(product?.cnh){
        product.anexos.push({
            id_anexo: parseInt(product.cnh),
            type: "cnh"
        });
    }

    if(product.historico_manutencao){
        product.anexos.push({
            id_anexo: parseInt(product.historico_manutencao),
            type: "historico_manutencao"
        });
    }
    uploadImages.forEach((image: UploadMediaResponse) => {
        product.anexos?.push({
            id_anexo: image.id,
            type: "foto_produto"
        });
    });
    product.ipva_pago = product.ipva_pago === "true";
    product.ticket_to_auction = product.ticket_to_auction === "true";
    product.manually = product.manually === "true";
    product.spare_key = product.spare_key === "true";
    product.valor = InputMasker.maskMoneyWithoutRs(product.valor)
    product.valor_fipe = InputMasker.maskMoneyWithoutRs(product.valor_fipe)
    product.commission = Number(InputMasker.removemaskPercentage(product.commission))

    const response: AxiosResponse<Product> = await getApi().put("/api/product", product);
    return response.data;
}