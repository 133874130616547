import React from "react";
import {InputOptions} from "../Custom/SelectInputWithLabel";
import {ValidationError} from "../../Users/FinancialMovement/FinancialMovementList";

export type ClassNameLogic = {
    className: string;
    logic: boolean;
}

export type SelectInputWithClassNameLogicProps = {
    validation: any;
    fieldName: string;
    label: string;
    options: InputOptions[];
    classNameLogics: ClassNameLogic[];
}

export default function SelectInputWithClassNameLogic({validation, fieldName, options, classNameLogics, label}: SelectInputWithClassNameLogicProps) {
    return (
        <div className="mb-3">
            <label
                htmlFor="cashout_type"
                className="inline-block mb-2 text-base font-medium"
            >
                {label}
            </label>
            <select
                className={`${classNameLogics.filter((classNameLogic: ClassNameLogic) => classNameLogic.logic).map((classNameLogic: ClassNameLogic) => classNameLogic.className).join(" ")} form-input focus:outline-none focus:border-custom-500 font-bold`}
                data-choices
                data-choices-search-false
                id={fieldName}
                name={fieldName}
                onChange={validation.handleChange}
                value={validation.values?.[fieldName] || ""}
            >
                {
                    options.map((option: InputOptions) => (
                        <option key={option.value} value={option.value}>{option.description}</option>
                    ))
                }
            </select>
            <ValidationError validation={validation} fieldName={fieldName}/>
        </div>
    )
}