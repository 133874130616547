import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import AuthUser, {User} from "../../../Backend/Services/Auth/AuthUser";

interface LoginState {
    user: User|string|null;
    error: string;
    success: boolean;
    isUserLogout: boolean;
}

const initialState: LoginState = {
    user: null,
    error: "",
    success: false,
    isUserLogout: false
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        loginSuccess(state: LoginState, action: PayloadAction<User>) {
            state.user = action.payload;
            state.success = true;
        },
        loginError(state: LoginState, action: PayloadAction<any>) {
            state.error = action.payload?.response?.data?.message ?? action.payload.message;
            state.success = false;
        },
        logoutSuccess(state: LoginState, action: PayloadAction<boolean>) {
            state.isUserLogout = action.payload;
        }
    },
});

export const { loginSuccess, loginError, logoutSuccess } = loginSlice.actions;
export default loginSlice.reducer;
