import React, {useEffect, useMemo, useState} from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import TableContainer from 'Common/TableContainer';

import {FileDown, Search} from 'lucide-react';
import {ToastContainer} from 'react-toastify';
import useHandleAxiosRequest from "../../Hooks/useHandleAxiosRequest";
import listSales, {SaleList} from "../../Backend/Api/Sales/list-sales";
import Flatpickr from "react-flatpickr";
import CsvFormatter from "../../Backend/Services/ExportCsv/CsvFormatter";
import CsvHelper from "../../Backend/Services/ExportCsv/CsvHelper";
import LoadingModal from "../Components/Custom/LoadingModal";

const SalesList = () => {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [salesList, , handleSalesListRequest] = useHandleAxiosRequest(() => listSales(), []);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        handleSalesListRequest(() => listSales(startDate, endDate))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    }, [startDate, endDate]);

    function handleDownloadCsv() {
        const saleCsvFormat = CsvFormatter.formatSalesToCsvObject(salesList as SaleList[]);
        CsvHelper.handleDownloadCsv(saleCsvFormat, 'relatorio_de_vendas.csv');
    }

    function firstDayOfTheMonth() {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    function lastDayOfTheMonth() {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    const ProductType = ({item}: { item: any }) => {
        switch (item) {
            case "veiculo_proprio":
                return (
                    <span
                        className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex justify-center items-center status"
                    >
                        Veiculo Proprio
                    </span>
                );

            default:
                return (
                    <span
                        className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status"
                    >
                        Veiculo Consignado
                    </span>
                );
        }
    };

    const columns = useMemo(() => [
            {
                header: "ID",
                accessorKey: "id",
                enableColumnFilter: false,
            },
            {
                header: "Vendedor",
                accessorKey: "seller",
                enableColumnFilter: false,
            },
            {
                header: "Tipo do veiculo",
                accessorKey: "productType",
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <ProductType item={cell.getValue()}/>
                )
            },
            {
                header: "Marca",
                accessorKey: "productBrand",
                enableColumnFilter: false,
            },
            {
                header: "Modelo",
                accessorKey: "productModel",
                enableColumnFilter: false,
            },
            {
                header: "Placa",
                accessorKey: "productPlate",
                enableColumnFilter: false,
            },
            {
                header: "Valor do veiculo",
                accessorKey: "productValue.amountString",
                enableColumnFilter: false,
                cell: (cell: any) => 'R$ ' + cell.getValue()
            },
            {
                header: "Valor da venda",
                accessorKey: "value.amountString",
                enableColumnFilter: false,
                cell: (cell: any) => 'R$ ' + cell.getValue()
            },
            {
                header: "Data da Venda",
                accessorKey: "createdAt",
                enableColumnFilter: false,
            },

        ], []
    );

    return (
        <React.Fragment>
            <BreadCrumb title='Vendas' pageTitle='Listagem'/>
            <ToastContainer closeButton={false} limit={1}/>
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
                <div className="xl:col-span-12">
                    <div className="card" id="usersTable">
                        <div
                            className="card-body justify-between xl:flex md:flex 2xl:flex items-end justify-between gap-5 mt-5 sm:grid">
                            <div className="flex items-center">
                                <h6 className="text-15 grow">Lista de vendas</h6>
                            </div>
                            <div className='2xl:flex xl:flex md:flex sm:grid items-end justify-between mt-5 gap-5'>
                                <div className='2xl:w-1/2 xl:w-1/2 md:w-1/2 sm:w-full'>
                                    <h6 className="mb-1 text-15">Data inicial</h6>
                                    <Flatpickr
                                        options={{
                                            dateFormat: "d/m/Y",
                                        }}
                                        defaultValue={firstDayOfTheMonth().toLocaleDateString()}
                                        onChange={(e) => setStartDate(new Date(e[0]))}
                                        placeholder="Data inicial"
                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    />
                                </div>
                                <div className='2xl:w-1/2 xl:w-1/2 md:w-1/2 sm:w-full'>
                                    <h6 className="mb-1 text-15">Data final</h6>
                                    <Flatpickr
                                        options={{
                                            dateFormat: "d/m/Y",
                                        }}
                                        defaultValue={lastDayOfTheMonth().toLocaleDateString()}
                                        onChange={(e) => setEndDate(new Date(e[0]))}
                                        placeholder="Data inicial"
                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    />
                                </div>
                                <button
                                    type="button"
                                    onClick={() => handleDownloadCsv()}
                                    className="px-2 py-1.5 text-base text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 rounded-md  font-bold h-[40px] sm:w-full sm:mt-5 xl:w-[280px] 2xl:w-[280px] md:w-[280px] flex items-center justify-around sm:right"
                                >
                                    Exportar Relatorio
                                    <FileDown height={'30px'}/>
                                </button>
                            </div>
                        </div>
                        <div className="card-body relative">
                            {loading && <LoadingModal/>}
                            {
                                salesList.length > 0 ?
                                    <TableContainer
                                        isPagination={true}
                                        columns={(columns || [])}
                                        data={(salesList || [])}
                                        customPageSize={20}
                                        divclassName="-mx-5 -mb-5 overflow-x-auto"
                                        tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                                        theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                                        thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                                        tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                                        PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                                    />
                                    : <div className="noresult">
                                        <div className="py-6 text-center">
                                            <Search
                                                className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20"/>
                                            <h5 className="mt-2 mb-1">Nenhuma venda encontrada!</h5>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SalesList;
