import InputMasker from "../../Services/Helpers/InputMasker";
import getApi from "../axios/api";
import {AxiosResponse} from "axios";
import {FinancialMovement} from "../../../Providers/FinancialMovementProvider";


export default async function getMovementList(startDate?: Date, endDate?: Date): Promise<FinancialMovement[]> {
    let params: any = {};
    if (startDate) {
        params.start_date = InputMasker.formatDateToApi(startDate);
    }
    if (endDate) {
        params.end_date = InputMasker.formatDateToApi(endDate);
    }
    let queryString = new URLSearchParams(params).toString();

    const result: AxiosResponse<FinancialMovement[]> = await getApi().get('/api/finance/movement' + (queryString ? '?' + queryString : ''));
    return result.data;
}