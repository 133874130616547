import React from 'react';
import CountUp from 'react-countup';
import { TotalEmployeeChart } from "../../Dashboards/HR/Charts";
import { Wallet2 } from "lucide-react";
import FinancialMovementDateFilter from "./FinancialMovementDateFilter";
import { useFinancialMovement } from "../../../Providers/FinancialMovementProvider";

const FinancialMovementWidgets = () => {
    const { financialGraphs } = useFinancialMovement();
    return (
        <React.Fragment>
            <div className=" col-span-12 grid grid-cols-12 gap-x-5" >
                <div className="col-span-12  md:col-span-6 grid md:grid-cols-6 gap-x-2 grid-cols-12">
                    <div className=" card col-span-12 md:col-span-3" >
                        <div className="text-center card-body">
                            <div
                                className="flex items-center justify-center mx-auto rounded-full size-14 bg-custom-100 text-custom-500 dark:bg-custom-500/20">
                                <Wallet2 />
                            </div>
                            <h5 className="mt-4 mb-2">R$
                                <CountUp
                                    end={financialGraphs?.balance.current_amount ?? 0.00}
                                    decimals={2}
                                    className="counter-value"
                                />
                            </h5>
                            <p className="text-slate-500 dark:text-zink-200">Saldo atual</p>
                        </div>
                    </div>
                    <div className="card col-span-12 md:col-span-3">
                        <div className="text-center card-body">
                            <div
                                className="bg-red-100 flex items-center justify-center mx-auto rounded-full size-14 bg-custom-100 text-custom-500 dark:bg-custom-500/20">
                                <Wallet2 color='red' />
                            </div>
                            <h5 className="mt-4 mb-2">R$
                                <CountUp
                                    end={financialGraphs?.balance.pending_cash_out ?? 0.00}
                                    decimals={2}
                                    className="counter-value"
                                />
                            </h5>
                            <p className="text-slate-500 dark:text-zink-200">Despesas pendentes</p>
                        </div>
                    </div>
                    <div className="card col-span-12 md:col-span-3">
                        <div className="card-body">
                            <div className="grid grid-cols-12">
                                <div className="col-span-8 md:col-span-9">
                                    <p className="text-slate-500 dark:text-slate-200">Total de entradas</p>
                                    <h5 className="mt-3 mb-4">
                                        <CountUp
                                            end={financialGraphs?.movements.cashin.qty ?? 0}
                                            className="counter-value"
                                        />
                                    </h5>
                                </div>
                                <div className="col-span-4 md:col-span-3">
                                    <TotalEmployeeChart
                                        chartId="hiredCandidates"
                                        dataChartColor='["bg-green-500"]'
                                        series={[financialGraphs?.movements.cashin.percentage ?? 0]}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center gap-3 mt-3">
                                <p className="text-slate-500 dark:text-slate-200 grow">
                                    <span
                                        style={{ color: financialGraphs?.movements.cashin.monthly_growth_percentage_type == 'positive' ? 'green' : 'red' }}
                                        className="font-medium"
                                    >
                                        {financialGraphs?.movements.cashin.monthly_growth_percentage ?? 0}%
                                    </span>
                                    {financialGraphs?.movements.cashin.monthly_growth_percentage_type == 'positive' ? 'Aumento' : 'Queda'}
                                </p>
                                <p className="text-slate-500 dark:text-slate-200">Este Mês</p>
                            </div>
                        </div>
                    </div>
                    <div className=" card col-span-12 md:col-span-3">
                        <div className="card-body">
                            <div className="grid grid-cols-12">
                                <div className="col-span-8 md:col-span-9">
                                    <p className="text-slate-500 dark:text-slate-200">Total de saidas</p>
                                    <h5 className="mt-3 mb-4">
                                        <CountUp
                                            end={financialGraphs?.movements.cashout.qty ?? 0}
                                            className="counter-value"
                                        />
                                    </h5>
                                </div>
                                <div className="col-span-4 md:col-span-3">
                                    <TotalEmployeeChart
                                        chartId="rejectedCandidates"
                                        dataChartColor='["bg-red-500"]'
                                        series={[financialGraphs?.movements.cashout.percentage ?? 0]}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center gap-3 mt-3">
                                <p className="text-slate-500 dark:text-slate-200 grow">
                                    <span
                                        style={{ color: financialGraphs?.movements.cashout.monthly_growth_percentage_type == 'positive' ? 'green' : 'red' }}
                                        className="font-medium"
                                    >
                                        {financialGraphs?.movements.cashout.monthly_growth_percentage}%
                                    </span>
                                    {financialGraphs?.movements.cashout.monthly_growth_percentage_type == 'positive' ? 'Aumento' : 'Queda'}
                                </p>
                                <p className="text-slate-500 dark:text-slate-200">Este Mês</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-span-12 md:col-span-6'>
                    <FinancialMovementDateFilter />
                </div>
            </div>


        </React.Fragment>
    );
};

export default FinancialMovementWidgets;
