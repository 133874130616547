export default class InputMasker {
    static maskMoney(value: string | number): string {
        if(value == 0) {
            return 'R$ 0,00'
        }
        if (!value) {
            return ''
        }
        if (typeof value === 'number') {
            value = value.toString()
        }
        const numberValue = value?.replace(/\D/g, "") ?? '';
        return new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
        }).format(Number(numberValue) / 100);
    }

    static maskPhone(value: string | number): string {
        if (!value) {
            return ''
        }
        if (typeof value === 'number') {
            value = value.toString()
        }
        const numberValue = value?.replace(/\D/g, "") ?? '';
        return numberValue?.replace(/^(\d{2})(\d{5})(\d{4}).*/, "($1) $2-$3") ?? '';
    }

    static maskMoneyWithoutRs(value: string | number): string {
        if (!value) {
            return ''
        }
        if (typeof value === 'number') {
            value = value.toString()
        }
        return this.removeMoneyRs(this.maskMoney(value));
    }

    static unmaskMoney(value: string | number): string {
        if (!value) {
            return ''
        }
        if (typeof value === 'number') {
            value = value.toString()
        }
        return value?.replace(/\D/g, "") ?? '';
    }

    static removeMoneyRs(value: string | number): string {
        if (!value) {
            return ''
        }
        if (typeof value === 'number') {
            value = value.toString()
        }
        return value?.replace("R$", "").trim() ?? '';
    }

    static maskPureString(value: string | number): string {
        if (!value) {
            return ''
        }
        if (typeof value === 'number') {
            value = value.toString()
        }
        return value?.replace(/\d/g, "") ?? '';
    }

    static maskYear(value: string | number): string {
        if (!value) {
            return ''
        }
        if (typeof value === 'number') {
            value = value.toString()
        }

        return value?.replace(/\D/g, "")?.substring(0, 4) ?? '';
    }

    static maskNumber(value: string | number): string {
        if (!value) {
            return ''
        }
        if (typeof value === 'number') {
            value = value.toString()
        }
        return value?.replace(/\D/g, "") ?? '';
    }

    static formatDateToApi(date: Date){
        return date?.toISOString().split('T')[0] ?? ''
    }

    static formatApiDateToDateClass(date: string) {
        date = date?.split(' ')[0] ?? '';
        const [year, month, day] = date.split('-').map(Number);

        return new Date(year, month - 1, day);
    }

    static formatDateToUser(date: string){
        const onlyDate = date?.split(' ')[0] ?? '';
        return onlyDate.split('-').reverse().join('/')
    }


    static maskPercentage(value: string | number): string {
        if (!value && value !== 0) {
            return '';
        }
        if (typeof value === 'number') {
            value = value.toString();
        }

        const numberValue = value.replace(/[^\d.]/g, '');

        if (numberValue === '') {
            return '';
        }

        let numericValue = parseFloat(numberValue);
        if (isNaN(numericValue)) {
            return '';
        }

        if (numericValue > 100) {
            numericValue = parseFloat(numberValue.slice(0, -1)) || 0;

            if (numericValue > 100) {
                numericValue = 100;
            }
        }

        numericValue = Math.floor(numericValue * 100) / 100;

        return `${numericValue}%`;
    }

    static maskPercentageInput(value: string | number): string {
        if (!value && value !== 0) {
            return '';
        }
    
        if (typeof value === 'number') {
            value = value.toString();
        }
    
        let numberValue = value.replace(/[^\d.]/g, '');
    
        if (numberValue === '') {
            return '';
        }
        if(parseInt(numberValue) > 100) {
            return '100'
        }

        return numberValue.slice(0, 3);
    }


    static removemaskPercentage(value: string | number): string {
        if (!value) {
            return ''
        }
        if (typeof value === 'number') {
            value = value.toString()
        }

        return value.replace(/[^\d.]/g, '');
    }
}