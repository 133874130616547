import getApi from "../axios/api";
import {AxiosResponse} from "axios";

export type Role = {
    id: number;
    name: string;
    description: string;
    display_name: string;

}
export default async function getRoles(): Promise<Role[]> {
    const result: AxiosResponse<Role[]> = await getApi().get("/api/auth/roles");
    return result.data;
}