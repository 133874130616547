import {SaleList} from "../../Api/Sales/list-sales";
import {FinancialMovement} from "../../../Providers/FinancialMovementProvider";
import {Product} from "../../Api/Product/get-products";

export default class CsvFormatter {
    static formatSalesToCsvObject(sales: SaleList[]) {
        return sales.map(sale => {
            return {
                'ID': sale.id,
                'Vendedor': sale.seller,
                'Tipo do Veiculo': sale.productType === 'veiculo_proprio' ? 'Veiculo Próprio' : 'Veiculo Consignado',
                'Marca': sale.productBrand,
                'Modelo': sale.productModel,
                'Placa': sale.productPlate,
                'Valor do veiculo': (sale.productValue.amountCents > 0 ? sale.productValue.amountCents / 100 : 0).toFixed(2),
                'Valor da Venda': (sale.value.amountCents > 0 ? sale.value.amountCents / 100 : 0).toFixed(2),
                'Comissão': (sale.commission.amountCents > 0 ? sale.commission.amountCents / 100 : 0).toFixed(2),
                'Data': sale.createdAt,
            }
        })
    }

    static formatFinancialMovementToCsvObject(movements: FinancialMovement[]) {
        return movements.map(movement => {
            const status = movement.is_completed
                ? (movement.type === 'cash_out' ? 'Pago': 'Recebido')
                : 'Pendente';
            return {
                'Tipo': movement.type === 'cash_in' ? 'Entrada' : 'Saida',
                'Valor': (movement.amount > 0 ? movement.amount / 100 : 0).toFixed(2),
                'Descrição': movement.description.replace(',', ' ').replace(';', ' '),
                'status': status,
                'Data': movement.date,
            }
        })
    }

    static formatProductToCsvObject(products: Product[]) {
        return products.map(product => {
            return {
                'ID': product.id,
                'Marca': product.marca,
                'Modelo': product.modelo,
               'Ano': product.ano,
                'Placa': product.placa,
                'Cor': product.cor,
                'Km': product.km,
                'Ano de Fabricação': product.fabricacao,
                'Valor': (product.valor.amount_cents > 0 ? product.valor.amount_cents / 100 : 0).toFixed(2),
                'Valor Fipe': (product.valor_fipe.amount_cents > 0 ? product.valor_fipe.amount_cents / 100 : 0).toFixed(2),
                'Porcentagem de Comissão': (product.commission ?? 0) + '%',
                'Diferença': (product.diferenca.amount_cents > 0 ? product.diferenca.amount_cents / 100 : 0).toFixed(2),
                'Tipo': product.product_type.name === 'veiculo_proprio' ? 'Veiculo Próprio' : 'Veiculo Consignado',
                'Criado por': product.created_by,
                'IPVA Pago': product.ipva_pago ? 'Sim' : 'Não',
                'Passagem em leilão': product.ticket_to_auction ? 'Sim' : 'Não',
                'Manualmente': product.manually ? 'Sim' : 'Não',
                'Chave reserva': product.spare_key ? 'Sim' : 'Não',
                'Status': product.status?.name,
            }
        });
    }
}