
import { Download, EyeIcon, Trash2 } from "lucide-react";
import { UploadMediaResponse } from "../../../Backend/Api/Media/upload-media";
import env from "../../../env";
import AuthToken from "../../../../src/Backend/Services/Auth/AuthToken";
import downloadMedia from "Backend/Api/Media/download-media";
import { toast } from "react-toastify";
export type ImageModalProps = {
    isDocument?: boolean;
    image: UploadMediaResponse,
    onDelete: (image: UploadMediaResponse) => void
};

export default function ImageModal({ image, onDelete, isDocument }: ImageModalProps) {
    const downloadImage = async () => {
        try {

            const response: any = await fetch(`${env.apiBaseUrl}/api/media/download/${image.token}`, {
                headers: {
                    Authorization: 'Bearer ' + AuthToken.getToken(),
                },
            });

            if (!response.ok) {
                throw new Error(`Erro ao buscar a imagem: ${response.statusText}`);
            }

            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = `${image.token}.${image.extension}`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            URL.revokeObjectURL(blobUrl);
        } catch (error) {
            toast("Erro ao baixar a imagem!", { autoClose: 2000, type: "error" })
        }
    };

    const viewTargetDocument = async () => {
        const url = image.url;
        
        try {

            const response: any = await fetch(`${env.apiBaseUrl}/api/media/download/${image.token}`, {
                headers: {
                    Authorization: 'Bearer ' + AuthToken.getToken(),
                },
            });

            if (!response.ok) {
                throw new Error(`Erro ao buscar a imagem: ${response.statusText}`);
            }
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);

            const newWindow = window.open();
            if (newWindow) {
                newWindow.location.href = blobUrl;
            } else {
                alert('Pop-up blocked. Please allow pop-ups for this site.');
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    
    return (
        <>
            {!isDocument && <div
                className="relative mb-0 overflow-hidden card md:col-span-6 lg:col-span-4 xl:col-span-3 group/item w-[250px] flex-none"
            >
                <div className="card-body">
                    <img src={image?.url} alt="" className="m-auto" />
                    <div
                        className="absolute flex gap-2 transition-all duration-200 ease-linear -translate-x-1/2 translate-y-1/2 opacity-0 bottom-1/3 group-hover/item:opacity-100 group-hover/item:bottom-1/2 left-1/2"
                    >
                        <button
                            type="button"
                            className="flex items-center justify-center size-[37.5px] transition-all duration-200 ease-linear p-0 rounded-full text-red-500 btn bg-white border-white shadow-md hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 active:text-white active:bg-red-600 active:border-red-600 dark:bg-zink-600 dark:border-zink-500 dark:hover:bg-red-600"
                            onClick={() => onDelete(image)}
                        >
                            <Trash2 className="h-4" /></button>
                        <button
                            type="button"
                            className="flex items-center justify-center size-[37.5px] transition-all duration-200 ease-linear p-0 rounded-full text-sky-500 btn bg-white border-white shadow-md hover:text-white hover:bg-sky-600 hover:border-sky-600 focus:text-white focus:bg-sky-600 focus:border-sky-600 active:text-white active:bg-sky-600 active:border-sky-600 dark:bg-sky-600 dark:border-zink-500 dark:hover:bg-sky-600"
                            onClick={downloadImage}
                        >
                            <Download className="h-4" />
                        </button>
                    </div>
                </div>

            </div>}
            {isDocument && <div className="flex gap-2">
                <button
                    type="button"
                    className="flex items-center justify-center w-[120px] h-[40px] transition-all duration-200 ease-linear p-0 rounded-full text-sky-500 btn bg-white border-white shadow-md hover:text-white hover:bg-sky-600 hover:border-sky-600 focus:text-white focus:bg-sky-600 focus:border-sky-600 active:text-white active:bg-sky-600 active:border-sky-600 dark:bg-sky-600 dark:border-zink-500 dark:hover:bg-sky-600"
                    onClick={downloadImage}
                >
                    <Download className="h-10" />
                </button>

                <button
                    type="button"
                    className="flex items-center justify-center w-[120px] h-[40px] transition-all duration-200 ease-linear p-0 rounded-full text-sky-500 btn bg-white border-white shadow-md hover:text-white hover:bg-sky-600 hover:border-sky-600 focus:text-white focus:bg-sky-600 focus:border-sky-600 active:text-white active:bg-sky-600 active:border-sky-600 dark:bg-sky-600 dark:border-zink-500 dark:hover:bg-sky-600"
                    onClick={viewTargetDocument}
                >
                    <EyeIcon className="h-10" />
                </button>
            </div>}
        </>
    )
}