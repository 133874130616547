import React from "react";

export type InputOptions = {
    value: any;
    description: string;
};

export type InputWithLabelProps = {
    validation: any;
    name: string;
    label: string;
    mask?: (value: string) => string;
    id: string;
    type?: string;
    value?: string;
    defaultOption?: string;
    disabled?: boolean;
    options: InputOptions[];
}

export default function SelectInputWithLabel(
    {
        validation,
        name,
        id,
        options,
        defaultOption,
        value,
        label,
        disabled,
    }: InputWithLabelProps
) {
    return (
        <div className="mb-3">
            <label
                htmlFor={id}
                className="inline-block mb-2 text-base font-medium"
            >
                {label}
            </label>
            
            <select
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                id={id}
                name={name}
                onChange={validation.handleChange}
                value={value ?? validation.values[name] ?? ""}
                disabled={disabled}
            >
                <option value="">{defaultOption ?? "selecione uma opção"}</option>
                {options.map((option: InputOptions, index: number) => (
                    <option
                        key={index}
                        value={option.value}
                    >
                        {option.description}
                    </option>
                ))}
            </select>
            {(validation.touched[name] ?? null) && (validation.errors[name] ?? null) ? (
                <p className="text-red-400">{validation.errors[name]}</p>
            ) : null}
        </div>
    )
}