import getApi from "../axios/api";
import {AxiosResponse} from "axios";
import InputMasker from "../../Services/Helpers/InputMasker";

export type CreateSalesInput = {
    product_id: number;
    customer_name: string;
    customer_phone: string;
    amount: string;
}

export default async function createSales(sales: CreateSalesInput) {
    sales.amount = InputMasker.maskMoneyWithoutRs(sales.amount);
    const response: AxiosResponse<any> = await getApi().post("/api/sales", sales);
    return response.data;
}
