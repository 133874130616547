export type Env = {
    developmentMode: boolean;
    apiBaseUrl: string;
    productionMenuName: string;
    publicUrl: string;
}

const env: Env = {
    developmentMode: !!(process.env?.REACT_APP_DEVELOPMENT_MODE ?? false),
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL ?? "",
    productionMenuName: process.env.REACT_APP_PRODUCTION_MENU_NAME ?? "Menu",
    publicUrl: process.env.REACT_APP_PUBLIC_URL ?? "",
}

export default env;